import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import SignUpNavbar from './SignUpNavbar';
// import DashboardSidebar from './DashboardSidebar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
  // [theme.breakpoints.up('md')]: {
  //   maxWidth: '90vw',
  //   margin: 'auto'
  // }
}));
const APP_BAR_MOBILE = 64;
const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingLeft: 24,
  paddingRight: 24,
  paddingTop: APP_BAR_MOBILE,
  position: 'relative'

  // paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function AdharLayout() {
  return (
    <>
      <RootStyle>
        <SignUpNavbar />
        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
    </>
  );
}
