import { createSlice } from '@reduxjs/toolkit';
import { getOfferIntroDetailsApi } from './onboardApi';

const initialState = {
  // businessSelected: {},
  offerIntroDetails: {},
  isOfferIntroLoading: false
};

const slice = createSlice({
  name: 'onboard',
  initialState,
  reducers: {},
  extraReducers: {
    [getOfferIntroDetailsApi.pending]: (state) => {
      state.isOfferIntroLoading = true;
    },
    [getOfferIntroDetailsApi.fulfilled]: (state, action) => {
      state.offerIntroDetails = action.payload;
      state.isOfferIntroLoading = false;
    },
    [getOfferIntroDetailsApi.rejected]: (state) => {
      state.isOfferIntroLoading = false;
    }
  }
});
export default slice.reducer;
