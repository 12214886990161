import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { localStorageKey } from './constants/common';

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

export function isValidPhoneNumber(phoneNumber) {
  const upPhNo = phoneNumber?.slice(-10);
  const phoneValidateRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  if (upPhNo.match(phoneValidateRegex)) {
    return true;
  }
  return false;
}
export function checkImage(url, successCB, errCB) {
  const image = new Image();
  image.onload = function () {
    if (this.width > 0) {
      console.log('image exists');
      successCB();
    }
  };
  image.onerror = function () {
    console.log("image doesn't exist");
    errCB();
  };
  image.src = url;
}

export function isAfterDate(mainDate, withDate) {
  return moment(mainDate).isAfter(moment(withDate));
}
export function clearPartnerCache() {
  localStorage.removeItem(localStorageKey.distribution);
  localStorage.removeItem(localStorageKey.partnerDetail);
  localStorage.removeItem(localStorageKey.partnerBankAmount);
  localStorage.removeItem(localStorageKey.partnerBankAmountEditable);
  localStorage.removeItem(localStorageKey.ifPartnerNavigatedToBankTransfer);
  localStorage.removeItem(localStorageKey.partnerLocQuery);
  localStorage.removeItem(localStorageKey.deeplinkParam);
}

export function clearPartnerAmountCache() {
  localStorage.removeItem(localStorageKey.partnerBankAmount);
  localStorage.removeItem(localStorageKey.partnerBankAmountEditable);
  localStorage.removeItem(localStorageKey.ifPartnerNavigatedToBankTransfer);
  localStorage.removeItem(localStorageKey.deeplinkParam);
}

export function isValidDate(timestamp) {
  const valid = new Date(timestamp).getTime() > 0;
  return valid;
}

export function appendSearchParams(url) {
  const searchParams = window?.location?.search;
  if (searchParams) return `${url}${window?.location?.search}`;
  return url;
}

export function getRedirectPath() {
  const queryParams = queryString.parse(window?.location?.search, true);
  // const urlSearchParams = new URLSearchParams(window?.location?.search);
  const redirectPath = queryParams?.deeplink;

  console.log(`redirectPath ${redirectPath}`);
  if (redirectPath) {
    delete queryParams.deeplink;
    return `${redirectPath}?${queryString.stringify(queryParams)}`;
  }
  return '';
}
