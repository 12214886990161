// scroll bar
import 'simplebar/src/simplebar.css';

// i18n
import './locales/i18n';

// lazy image
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider as ReduxProvider } from 'react-redux';
import { Typography } from '@mui/material';
import ErrorBoundary from './components/ErrorBoundary';
import { AuthProvider } from './contexts/JWTContext';

import { store, persistor } from './redux/store';

import App from './App';
import LoadingScreen from './components/LoadingScreen';
import reportWebVitals from './reportWebVitals';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { vapidPublicKey } from './config';

if (process.env.NODE_ENV !== 'development') console.log = () => {};

// if (window.self === window.top) {
ReactDOM.render(
  <ErrorBoundary>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={<LoadingScreen />} persistor={persistor}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter>
              <AuthProvider>
                <App />
              </AuthProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);

serviceWorkerRegistration.unregister({ vapidPublicKey });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// }
// else {
//   ReactDOM.render(<UnsecuredPage />, document.getElementById('root'));
// }
