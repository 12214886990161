import { Outlet } from 'react-router-dom';
import _ from 'lodash';
// material
import { styled } from '@mui/material/styles';
//
import CkycNavbar from './CkycNavbar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
}));
const APP_BAR_MOBILE = 64;
const MainStyle = styled('div')(() => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingLeft: 24,
  paddingRight: 24,
  paddingTop: APP_BAR_MOBILE
}));

// ----------------------------------------------------------------------

export default function CkycLayout() {
  return (
    <>
      <RootStyle>
        <CkycNavbar />

        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
    </>
  );
}
