import axios from 'axios';

// async function getLocationFromIP() {
//   // const url = 'https://am.i.mullvad.net/json';
//   // const url = 'http://ip-api.com/json';
//   const url = 'https://api.ipdata.co/?api-key=3b629f1e7571bad2c922b7f51503e6cad4a2acb7386783b2efcb99ef';

//   try {
//     let ipGeo = await axios.get(url);
//     ipGeo = ipGeo?.data;
//     console.log('rev location is ---------', ipGeo);
//     await success({ coords: { latitude: ipGeo?.latitude, longitude: ipGeo?.longitude } });
//     // let revLocation = await axios.get(url);
//     // revLocation = revLocation?.data;
//     // const coords = { latitude: revLocation?.ll[0], longitude: revLocation?.ll[1] };
//     // console.log('rev location is ---------', revLocation);
//   } catch (err) {
//     console.log('err is', err);
//   }
// }

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};
async function success(pos) {
  const crd = pos.coords;
  console.log('Your current position is:');
  console.log(`Latitude : ${crd.latitude}`);
  console.log(`Longitude: ${crd.longitude}`);
  console.log(`More or less ${crd.accuracy} meters.`);
  let revLocation = {};
  try {
    revLocation = await axios.get(
      `https://nominatim.openstreetmap.org/reverse?lat=${crd.latitude}&lon=${crd.longitude}&format=json`
    );
    revLocation = revLocation?.data;
  } catch (err) {
    console.log('err is', err);
  }
  localStorage.setItem(
    'userLocation',
    JSON.stringify({ latitude: crd.latitude, longitude: crd.longitude, ...revLocation })
  );
}

function errors(err) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
  // TODO: can be implemented in reverse proxy level
  // getLocationFromIP();
}

export async function getUserLocation() {
  if (navigator.geolocation) {
    try {
      // even chrome uses safari web engine on iOS
      const isIOSSafari = !!window.navigator.userAgent.match(/Version\/[\d\\.]+.*Safari/);
      if (window.safari !== undefined || isIOSSafari) {
        // safari browser
        navigator.geolocation.getCurrentPosition(success, errors);
      } else {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
          if (result.state === 'granted') {
            console.log(result.state);
            navigator.geolocation.getCurrentPosition(success);

            // If granted then you can directly call your function here
          } else if (result.state === 'prompt') {
            console.log(result.state);
            // getLocationFromIP();
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === 'denied') {
            // TODO: can be done by reverse proxy level
            // getLocationFromIP();
            // If denied then you have to show instructions to enable location
          }
          result.onchange = function () {
            console.log(result.state);
          };
        });
      }
    } catch (e) {
      console.log(e, 'Error in geolocation');
    }
  } else {
    console.log('Sorry Not available!');
  }
}
