import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { bankTransactionStatus, transactionStatus } from 'utils/constants/bankTransactionConst';
import {
  initiateBankTransferApi,
  initiatePennyTransferApi,
  getOutstandingApi,
  getTransactionApi,
  getAutoPaySubscriptionDetailsApi
} from './transferApi';

// mock response
// import getOutstandingRes from './mockResponse/getOutstanding.json';
// import getTransactionRes from './mockResponse/getTransaction.json';

const initialState = {
  // businessSelected: {},
  isTransferProcessing: false,
  bankTransferDetails: {},
  transferRequest: {},
  outStandingDetails: {},
  isPennyFlow: false,
  repaymentTransactionDetails: {},
  repayTranDetailsDisplay: {}
};

const slice = createSlice({
  name: 'transfer',
  initialState,
  reducers: {
    setTransferRequest: (state, action) => {
      state.transferRequest = action.payload.params;
      state.isPennyFlow = action.payload.isPennyFlow;
    }
  },
  extraReducers: {
    [initiateBankTransferApi.pending]: (state) => {
      state.isTransferProcessing = true;
    },
    [initiateBankTransferApi.fulfilled]: (state, action) => {
      state.bankTransferDetails = action.payload;
    },
    [initiatePennyTransferApi.pending]: (state) => {
      state.isTransferProcessing = true;
    },
    [initiatePennyTransferApi.fulfilled]: (state, action) => {
      state.bankTransferDetails = action.payload;
    },
    [getOutstandingApi.fulfilled]: (state, action) => {
      const { payload } = action;
      // For mocking
      // const payload = getOutstandingRes.success;

      if (payload) {
        const outstdDetails = {
          outstandingAmount: payload?.current_outstading,
          isSelfRepaymentEnabled: payload?.enable_self_repayment
        };
        outstdDetails.ifAnyOutstandingAmount = outstdDetails.outstandingAmount > 0;
        if (!outstdDetails.ifAnyOutstandingAmount) {
          outstdDetails.dialogMsg = payload.msg;
        }
        if (!_.isEmpty(payload?.terms_and_conditions_text)) {
          const urls = payload?.terms_and_conditions_links;
          let terms = payload?.terms_and_conditions_text;
          if (!_.isEmpty(urls)) {
            _.forEach(urls, (url) => {
              const reg = new RegExp(url?.name, 'gi');
              terms = terms.replace(
                reg,
                `<a style="color: #2EB67D" href=${url?.url} target="_blank">${url?.name} </a>`
              );
            });
          }
          outstdDetails.termsAndCondText = terms;
        }
        state.outStandingDetails = outstdDetails;
      }
    },
    [getTransactionApi.fulfilled]: (state, action) => {
      // state.repaymentTransactionDetails = action.payload;
      // const payload = getTransactionRes.success_repay;
      const { payload } = action;
      state.repaymentTransactionDetails = payload;
      const repayTranDetailsDisplay = {
        header: payload.user_remarks,
        displayTime: payload?.updated_at,
        status: payload?.status,
        transactionId: payload?.transaction_id,
        amount: payload?.amount,
        isUpi: payload?.is_upi
      };
      if (payload?.status === bankTransactionStatus.PENDING) {
        if (payload.request_status === bankTransactionStatus.INITIATED)
          repayTranDetailsDisplay.status = bankTransactionStatus.PROCESSING;
        // else if (payload.request_status === 'COMPLETED') {
        //   repayTranDetailsDisplay.status = transactionStatus.PENDING_EXIT;
        // }
        else {
          repayTranDetailsDisplay.status = bankTransactionStatus.PENDING;
        }
      }
      // repayTranDetailsDisplay.message = {
      //   displayMsg:
      //     ' There seems to be a payment system issue in Upi App. These cases are typically resolved in 3-5 days.',
      //   msgType: 'WARN'
      // };
      state.repayTranDetailsDisplay = repayTranDetailsDisplay;
    }
  }
});

export const bankTransferActions = slice.actions;

export default slice.reducer;
