import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // businessSelected: {},
  isRelogin: false
};

const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setReloginDialog: (state, action) => {
      state.isRelogin = action.payload;
    }
  }
});

export const commonActions = slice.actions;

export default slice.reducer;
