import { Box, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import React from 'react';

function DLImgComp(props) {
  const { dlItem } = props;
  return (
    <Box
      sx={{
        border: '1.5px solid #E01E5A',
        borderRadius: '5px',
        mt: '20px',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'rgba(224, 30, 90, 0.05)',
        width: '100%'
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <Box
          component="img"
          src={dlItem?.image_url || '/dl.webp'}
          sx={{ alignItems: 'center', textAlign: 'center', height: '145px' }}
        />
        {!dlItem?.image_url && (
          <Box
            sx={{
              position: 'absolute',
              backgroundColor: 'rgba(255, 255, 255, 1)',
              border: '1.4px solid #E01E5A',
              padding: '5px',
              borderRadius: '5px',
              whiteSpace: 'nowrap',
              top: '100px',
              left: '12px',
              minWidth: '80px'
            }}
          >
            <ErrorIcon sx={{ position: 'absolute', ml: '89%', mt: -2, fontSize: '16px', color: '#E01E5A' }} />
            <Typography
              sx={{
                fontSize: '10px',
                fontWeight: '600',
                color: '#000',
                fontFamily: 'roboto'
                // textAlign: 'center'
              }}
            >
              {props?.name}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default DLImgComp;
