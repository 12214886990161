import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

// hooks
import { appendSearchParams } from 'utils/common';
import { LOADING } from 'routes/paths';
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const { isAuthenticated } = useAuth();
  // useEffect(() => {
  //   ReactGA.event({ category: 'onboard', label: 'logged_in', action: 'click' });
  // }, []);
  //  PATH_LOC_DASHBOARD.root
  if (isAuthenticated) {
    return <Navigate to={appendSearchParams(LOADING)} replace />;
  }

  return <>{children}</>;
}
