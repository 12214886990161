import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useLocales from 'hooks/useLocales';

// material
import { styled } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { AppBar, Toolbar, Typography, IconButton, Stack, Menu, MenuItem } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { PATH_EWI, LOAN_OFFER_ID, DUE_AMOUNT, EARLY_REPAYMENT, DUE_REPAYMENT, FULL_REPAYMENT } from 'routes/paths';
import { whatsApp } from 'config';
import { kycHelpConst, ewiHelpConst } from 'utils/constants/kycConst';
import { useSelector } from 'react-redux';

// components
//

// import NotificationsPopover from './NotificationsPopover';
// import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger ? 2 : 0,
    sx: { backgroundColor: trigger ? 'white' : 'transparent', transition: 'all 0.2s ease' }
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

const RootStyle = styled(AppBar)(({ theme }) => ({
  // backgroundColor: 'transparent',
  // boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
  height: 64
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  justifyContent: 'space-between',
  padding: '0px 24px'
}));

// ----------------------------------------------------------------------

EwiNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function EwiNavbar(props) {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { pathname } = useLocation();
  const { creditPlanDetails } = useSelector(({ planReducer }) => planReducer);
  // const pathName = window.location.pathname?.split('/').pop();

  const onMenuBt = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onBtSupport = () => {
    // if (pathname) {
    const updPathname = pathname.substr(pathname.lastIndexOf('/'));
    const phone = localStorage.getItem('phone');
    let whatsAppUrl = `${whatsApp.url}/${whatsApp.number}`;
    let text;
    if (pathname === PATH_EWI.loanAmount) {
      text = ewiHelpConst.INSTANT_LOAN_CONFIRMATION;
    } else if (pathname === PATH_EWI.plandetails) {
      text = ewiHelpConst.PLAN_DETAILS;
    } else if (pathname === `/ewi/loan-offer/view${updPathname}`) {
      text = ewiHelpConst.LOAN_OFFERS;
    } else if (pathname === PATH_EWI.loanConfirmation) {
      text = ewiHelpConst.INSTANT_LOAN;
    } else text = 'Help KYC';
    whatsAppUrl = whatsAppUrl.concat(`?text=${encodeURI(`${text}\n${phone}`)}`);
    window.open(whatsAppUrl, '_blank').focus();
  };

  const getHeader = () => {
    let header = 'Instant Loan Offer';
    if (searchParams.get('employerId') === 'true') {
      header = 'Verify Your Employment';
      return header;
    }
    // const updPathname = pathname.substr(pathname.lastIndexOf('/'))
    switch (pathname) {
      case LOAN_OFFER_ID:
        header = 'Instant Loan Offer';
        break;
      case DUE_REPAYMENT:
        header = 'Due payment';
        break;
      case FULL_REPAYMENT:
        header = 'Full payment';
        break;
      case PATH_EWI.loanOffer:
        header = 'Instant Loan Offer';
        break;
      case PATH_EWI.loanAmount:
        header = 'Instant Loan';
        break;
      case PATH_EWI.loanConfirmation:
        header = 'Loan Confirmation';
        break;
      case PATH_EWI.plandetails:
        header = 'Instant Loan';
        break;
      case DUE_AMOUNT:
        header = 'Due Payment';
        break;
      case EARLY_REPAYMENT:
        header = 'Early Repayment';
        break;
      default:
        break;
    }
    return header;
  };

  const showHelpIcon = () => {
    switch (pathname) {
      case LOAN_OFFER_ID:
        return true;
      case PATH_EWI.loanAmount:
        return true;
      // case PATH_EWI.loanOfferViewDetails:
      //   return true;
      case PATH_EWI.loanConfirmation:
        return true;
      case DUE_AMOUNT:
        return false;
      case EARLY_REPAYMENT:
        return false;
      case PATH_EWI.plandetails:
        return true;
      default:
        return true;
    }
  };

  const handleBackClick = () => {
    if (pathname.includes(PATH_EWI.loanOffer) || pathname?.includes(PATH_EWI.loanAmount)) {
      navigate('/', { replace: true });
      return;
    }
    if (pathname?.includes(PATH_EWI.loanConfirmation)) {
      navigate(PATH_EWI.loanAmount, { replace: true });
      return;
    }
    if (pathname?.includes(PATH_EWI.plandetails)) {
      navigate(PATH_EWI.root, { replace: true });
      return;
    }
    navigate(-1);
  };

  return (
    <>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        anchorEl={anchorEl}
        open={open}
        dense
        onClose={handleMenuClose}
      >
        <MenuItem onClick={onBtSupport}>{translate('common_contact_support_label')} </MenuItem>
      </Menu>
      <ElevationScroll {...props}>
        <RootStyle position="fixed">
          <ToolbarStyle>
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
              <IconButton edge="start" aria-label="back arrow" sx={{ mr: 1, color: 'black' }} onClick={handleBackClick}>
                <ArrowBackIcon />
              </IconButton>
              <Typography sx={{ color: 'black', fontWeight: 500, fontSize: 20 }} component="div">
                {getHeader()}
              </Typography>
            </Stack>
            {showHelpIcon() && (
              <IconButton edge="start" aria-label="help" sx={{ color: 'black' }} onClick={onMenuBt}>
                <HelpOutlineIcon />
              </IconButton>
            )}
          </ToolbarStyle>
        </RootStyle>
      </ElevationScroll>
    </>
  );
}
