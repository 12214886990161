import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import dashboardReducer from './dashboard';
import planReducer from './plans';
import loanReducer from './loans';
import transferReducer from './transfer';
import kycReducer from './kyc';
import commonReducer from './common';
import onboardReducer from './onboard';
import ewiReducer from './ewi';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};
const initialState = { isPageLoaded: true };

const rootReducer = combineReducers({
  ...initialState,
  dashboardReducer,
  planReducer,
  loanReducer,
  transferReducer,
  kycReducer,
  commonReducer,
  onboardReducer,
  ewiReducer
});

export { rootPersistConfig, rootReducer };
