export const featureConstant = {
  BANK_TRANSFER: 'BANK_TRANSFER',
  SCAN_AND_PAY: 'SCAN_AND_PAY'
};

export const PRODUCT_TYPE = {
  CREDIT: 'CREDIT'
};

export const LOCK_DETAILS_TYPE = {
  AUTO_PAY: 'Auto Pay'
};

export const SUB_PRODUCT_TYPE = {
  LINE_OF_CREDIT: 'LINE_OF_CREDIT',
  EASY_INSTALMENT: 'EASY_INSTALMENT'
};
