export const bankTransactionStatus = {
  PROCESSING: 'PROCESSING',
  BATCH_PROCESSING: 'BATCH_PROCESSING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  PENDING: 'PENDING',
  FAILURE: 'FAILURE',
  PAYMENT_RECEIVED: 'PAYMENT_RECEIVED',
  INITIATED: 'INITIATED'
};

export const transactionStatus = {
  PROCESSING: 'PROCESSING',
  PENDING: 'PENDING',
  FAILURE: 'FAILURE',
  SUCCESS: 'SUCCESS',
  PENDING_EXIT: 'PENDING_EXIT'
};

export const bankTransColor = {
  PENDING: '#D09C24',
  PROCESSING: '#185BDA',
  BATCH_PROCESSING: '#185BDA',
  FAILED: '#BA335D',
  FAILURE: '#BA335D',
  SUCCESS: '#279E6C',
  PAYMENT_RECEIVED: '#439E27'
};

export const bankTransTextBackgroundColor = {
  PENDING: '#FFFCF6',
  PROCESSING: '#F3F7FF',
  BATCH_PROCESSING: '#F3F7FF',
  FAILED: '#FFF3F7',
  FAILURE: '#FFF3F7',
  SUCCESS: '#F1FEF8'
};

export const repayMethod = {
  DEFAULT_UPI: 'DEFAULT_UPI',
  PAYMENT_LINK: 'PAYMENT_LINK'
};
export const pennylessVerificationStatus = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  PENDING: 'PENDING',
  BLOCK: 'BLOCK',
  REJECT: 'REJECT'
};

export const txnStatusConfig = {
  [bankTransactionStatus.FAILED]: {
    color: '#BA335D',
    gradient: 'linear-gradient(149.48deg, #632236 -17.93%, #C86282 112.84%)',
    svg: '/static/transaction-status/failed.svg'
  },
  [bankTransactionStatus.FAILURE]: {
    color: '#BA335D',
    svg: '/static/transaction-status/failed.svg',
    gradient: 'linear-gradient(149.48deg, #632236 -17.93%, #C86282 112.84%)'
  },
  [bankTransactionStatus.PROCESSING]: {
    color: '#175BDA',
    gradient: 'linear-gradient(149.48deg, #0A275C -17.93%, #5086EC 112.84%)',
    svg: '/static/transaction-status/processing.svg'
  },
  [bankTransactionStatus.PENDING]: {
    color: '#D09C24',
    svg: '/static/transaction-status/pending.svg',
    gradient: 'linear-gradient(149.48deg, #765814 -17.93%, #E7C474 112.84%)'
  },
  [bankTransactionStatus.BATCH_PROCESSING]: {
    color: '#175BDA',
    gradient: 'linear-gradient(149.48deg, #0A275C -17.93%, #5086EC 112.84%)',
    svg: '/static/transaction-status/processing.svg'
  },
  [bankTransactionStatus.SUCCESS]: {
    color: '#2EB67D',
    svg: '/static/transaction-status/success.svg',
    gradient: 'linear-gradient(149.48deg, #175E40 -17.93%, #32CD8C 112.84%)'
  },
  [bankTransactionStatus.PAYMENT_RECEIVED]: {
    color: '#2EB67D',
    svg: '/static/transaction-status/success.svg',
    gradient: 'linear-gradient(149.48deg, #175E40 -17.93%, #32CD8C 112.84%)'
  },
  // todo: design?
  [bankTransactionStatus.INITIATED]: {
    color: '#BA335D',
    svg: '/static/transaction-status/failed.svg',
    gradient: 'linear-gradient(149.48deg, #632236 -17.93%, #C86282 112.84%)'
  }
};
