import React, { useState, useEffect } from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import HelpComponent from 'pages/kyc-v2/component/HelpComponent';
import videoConst from 'utils/constants/videoConst';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardHelpConst } from 'utils/constants/kycConst';
import CustomSubmitButton from 'components/CustomSubmitButton';
import useCommon from 'hooks/useComon';
import { localStorageKey } from 'utils/constants/common';
import { unwrapResult } from '@reduxjs/toolkit';
import * as Yup from 'yup';
import { PATH_KYC } from 'routes/paths';
import useLocales from 'hooks/useLocales';
import { completeUploadKycDocumentApi, getUserKycStatus, verifyUserPanNumber } from 'redux/kyc/kycApi';
import { kycActions } from 'redux/kyc';
import { CheckBox } from '@mui/icons-material';
import { whatsApp } from '../../config';

const regpan = /^([a-zA-Z]([a-zA-Z]([a-zA-Z]([a-zA-Z]([a-zA-Z]([0-9]([0-9]([0-9]([0-9]([a-zA-Z])?)?)?)?)?)?)?)?)?)?$/;

const panSchema = Yup.object().shape({
  panNo: Yup.string().matches(regpan)
});

function PanBottom() {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [checked, setChecked] = useState(true);
  const { panDetails = '', panFormDetails, panOwnerName } = useSelector(({ kycReducer }) => kycReducer);
  const kycItem = useSelector(({ kycReducer }) => kycReducer?.userKycDetails?.kyc_items);
  const dispatch = useDispatch();
  const [enable, setEnable] = useState(false);
  const panDocDetailsVal = localStorage.getItem(localStorageKey?.pan_doc);
  const panDocDetails = JSON.parse(panDocDetailsVal);
  const { closeAlertDialog, toggleDialog } = useCommon();

  const panItem = _.find(kycItem, (item) => item.kyc_type === 'PAN')?.tos?.[0];

  // const [showPanVerify, setPanVerify] = useState(false);
  const { pushGaEvent } = useCommon();
  const [err, setErr] = useState('');

  const [loading, setLoading] = useState(false);
  const showPanVerify = _.includes(pathname, PATH_KYC.pancard_details);
  const panSuccess = _.includes(pathname, PATH_KYC.pan_doc_success);
  const [reviewId, setReviewId] = useState('');
  const [termsDisc, setTermsDisc] = useState('');

  useEffect(() => {
    if (!_.isEmpty(panItem)) {
      const urls = panItem?.urls;
      let disclaimer = panItem?.terms_disclaimer;
      if (!_.isEmpty(urls)) {
        _.forEach(urls, (url) => {
          const reg = new RegExp(url?.name, 'gi');
          disclaimer = disclaimer.replace(
            reg,
            `<a style="color: #2EB67D" href=${url?.url} rel="noopener noreferrer" >${url?.name} </a>`
          );
        });
        const byClickReg = new RegExp('By clicking accept', 'gi');
        disclaimer = disclaimer.replace(byClickReg, '<br /> <br /><b>By clicking accept</b>');
      }
      setTermsDisc(disclaimer);
    }
  }, [panItem]);

  useEffect(() => {
    if (panDetails.length >= 10) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [panDetails]);

  const onBtSupport = () => {
    const phone = localStorage.getItem(localStorageKey.phone);
    let whatsAppUrl = `${whatsApp.url}/${whatsApp.number}`;
    const text = 'PAN already exists';
    whatsAppUrl = whatsAppUrl.concat(`?text=${encodeURI(`${text}\n${phone}`)}`);
    window.open(whatsAppUrl, '_blank').focus();
  };

  const onVerifyClick = () => {
    setLoading(true);
    pushGaEvent('pan_start', { category: 'ekyc', action: 'click' });

    // panNumberValidation
    if (regpan.test(panDetails)) {
      const params = {
        pan: panDetails,
        enable_pan_duplicate_check: true,
        kyc_user_review_required: true
      };
      if (checked) {
        params.acceptedTosDetails = {
          tosId: [panItem?.tos_id]
        };
      }
      dispatch(verifyUserPanNumber(params))
        .then(unwrapResult)
        .then((data) => {
          setReviewId(data?.kyc_review_id);
          setLoading(false);
          pushGaEvent('pan_verify', { category: 'ekyc', action: 'click', label: 'success' });
          if (data?.eligibility_details && !data?.eligibility_details?.is_eligible) {
            navigate(PATH_KYC.not_eligible_kyc_user);
          } else {
            navigate(PATH_KYC.pancard_details);
          }
        })
        .catch((err) => {
          console.log('err is', err);
          setLoading(false);
          pushGaEvent('pan_verify', { category: 'ekyc', action: 'click', label: 'error' });

          setErr(translate('pan_verify_tv_pan_error_label'));
          if (err?.code === 9) {
            toggleDialog({
              dialogLabel: 'PAN Duplication!',
              dialogContentText: 'PAN already exists.',
              buttonLabel: 'RE-ENTER PAN',
              buttonLabelClick: () => {
                closeAlertDialog();
                dispatch(kycActions.setPanDetails(''));
              },
              buttonTextLabel: translate('common_contact_support_label'),
              buttonTextLabelClick: onBtSupport
            });
          }
        });
    } else {
      setLoading(false);
    }
  };

  const onSubmitClick = () => {
    const panDocUplaodParams = localStorage.getItem('panDocUploadParams');
    if (panDocUplaodParams) {
      const panDocParams = JSON.parse(panDocUplaodParams);
      const param = {
        ...panDocParams,
        kyc_user_review_required: false,
        father_name: panDocParams?.father_name || panOwnerName?.pan_father_name || panFormDetails?.data?.fatherName
      };
      if (checked) {
        param.acceptedTosDetails = {
          tosId: [panItem?.tos_id]
        };
      }
      dispatch(completeUploadKycDocumentApi(param))
        .then(unwrapResult)
        .then((data) => {
          localStorage.removeItem('panDocUploadParams');
          setLoading(false);
          if (data?.doc_id) {
            pushGaEvent('pan_verify', { category: 'ekyc', action: 'click', label: 'success' });
            navigate(`${PATH_KYC.root}?skipdash=true`, { replace: true });
          }
        })
        .catch((err) => {
          console.log('err is', err);
          setLoading(false);
          pushGaEvent('pan_verify', { category: 'ekyc', action: 'click', label: 'error' });
        });
    } else {
      navigate(`${PATH_KYC.root}?skipdash=true`, { replace: true });
      setLoading(true);
      const params = {
        pan: panOwnerName?.pan_no,
        kyc_review_id: reviewId,
        father_name: panOwnerName?.pan_father_name || panFormDetails?.data?.fatherName,
        kyc_user_review_required: false
      };
      if (checked) {
        params.acceptedTosDetails = {
          tosId: [panItem?.tos_id]
        };
      }
      dispatch(verifyUserPanNumber(params))
        .then(unwrapResult)
        .then((data) => {
          setLoading(false);
          // setPanVerify(true);
          pushGaEvent('pan_verify', { category: 'ekyc', action: 'click', label: 'success' });
          localStorage.removeItem(localStorageKey.pan_doc);
          dispatch(getUserKycStatus());
          if (data?.eligibility_details && !data?.eligibility_details?.is_eligible) {
            navigate(PATH_KYC.not_eligible_kyc_user);
          } else {
            navigate(`${PATH_KYC.root}?skipdash=true`, { replace: true });
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          pushGaEvent('pan_verify', { category: 'ekyc', action: 'click', label: 'error' });
        });
    }
  };

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  return (
    <Box sx={{ paddingBottom: '24px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
        {!panSuccess && (
          <>
            <Typography sx={{ fontSize: '16px', color: '#FF5858', fontWeight: 700 }}>NOTE</Typography>
            <Typography sx={{ fontSize: '12px', color: '#424242', marginBottom: showPanVerify ? '20px' : '0px' }}>
              <span style={{ color: '#363636', fontWeight: 700 }}>PAN Name</span> should be the same as your
              <span style={{ color: '#363636', fontWeight: 700 }}> Bank A/C Name</span>
            </Typography>
          </>
        )}

        <Box sx={{ width: '100%', position: 'sticky', bottom: 0, backgroundColor: '#f8f9f9' }}>
          {!showPanVerify && (
            <Box sx={{ marginBottom: '20px' }}>
              <HelpComponent videoId={videoConst?.pan_video_id} />{' '}
            </Box>
          )}

          {/* <Box sx={{ width: '100%', position: 'absolute', bottom: '10%', backgroundColor: '#f8f9f9' }}> */}
          {showPanVerify ? (
            <>
              <CustomSubmitButton
                label="Proceed"
                disableBT={panFormDetails?.isEnabled}
                loadBt={loading}
                onButtonPress={onSubmitClick}
              />
            </>
          ) : (
            <>
              <FormGroup sx={{ padding: '0px' }}>
                <FormControlLabel
                  control={<Checkbox onChange={handleCheckboxChange} checked={checked} />}
                  label={
                    <Typography sx={{ textAlign: 'left', pt: 2 }}>
                      <div dangerouslySetInnerHTML={{ __html: termsDisc }} />
                    </Typography>
                  }
                />
              </FormGroup>
              <CustomSubmitButton
                label="Proceed"
                loadBt={loading}
                disableBT={!(enable && checked)}
                onButtonPress={onVerifyClick}
              />
            </>
          )}
          {/* </Box> */}
        </Box>
      </Box>
    </Box>
  );
}

export default PanBottom;
