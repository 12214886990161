import axios from 'utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createTransferParams } from 'utils/jwt';
import { kycActions } from '.';
// import checkCkycStatusRes from './mockResponse/checkCkycStatus.json';

const urls = {
  getUserStatus: 'common/kyc/GetKycStatus',
  checkCKyc: 'common/user/CheckCKYC',
  uploadKycDocuments: 'common/kyc/UploadKycDocuments',
  verifyPanNumber: 'common/kyc/VerifyPan',
  startDigilocker: 'common/kyc/digilocker/DigilockerStart',
  fetchDigilocker: 'common/kyc/digilocker/FetchAadhaarDetailsFromDigilocker',
  isNbfcLoanAccReady: 'credit/loan/IsNbfcLoanAccReady',
  completeUploadKycDocument: 'common/kyc/CompleteUploadKycDocument',
  completeKycUpdate: 'common/kyc/CompleteKycUpdate',
  FetchPincodeDetails: 'common/misc/FetchPincodeDetails'
};

export const checkCKycStatusApi = createAsyncThunk('karma/checkCKycStatusApi', async (params, thunkApi) => {
  try {
    thunkApi.dispatch(kycActions.setCkycRequestBody(params));
    const response = await axios.post(urls.checkCKyc, params);
    // const response = checkCkycStatusRes.successSchCKCYCFound;
    if (response.data?.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getUserKycStatus = createAsyncThunk('karma/getKycUserStatus', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.getUserStatus, {
      ...params,
      enable_kyc_verification_failed_status: true,
      enable_pan_document: true
    });
    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const uploadKycDocumentsApi = createAsyncThunk('karma/uploadKycDocuments', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.uploadKycDocuments, params);
    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const verifyUserPanNumber = createAsyncThunk('karma/verifyUserPanNumber', async (params, thunkApi) => {
  try {
    const updParams = await createTransferParams(params);
    updParams.acceptedTosDetails.location = updParams?.location;
    delete updParams.location;
    const response = await axios.post(urls.verifyPanNumber, updParams);
    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const startDigilockerApi = createAsyncThunk('karma/startDigilockerApi', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.startDigilocker, params);
    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const fetchDigilockerApi = createAsyncThunk('karma/fetchDigilockerApi', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.fetchDigilocker, params);
    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const isNbfcLoanAccReadyApi = createAsyncThunk('karma/IsNbfcLoanAccReadyApi', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.isNbfcLoanAccReady, params);
    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const completeUploadKycDocumentApi = createAsyncThunk(
  'karma/completeUploadKycDocument',
  async (params, thunkApi) => {
    try {
      const updParams = await createTransferParams(params);
      if (!updParams.acceptedTosDetails) {
        updParams.acceptedTosDetails = {};
      }
      updParams.acceptedTosDetails.location = updParams?.location;
      delete updParams.location;
      const response = await axios.post(urls.completeUploadKycDocument, updParams);
      if (response.data) {
        return response.data;
      }
      return thunkApi.rejectWithValue('Error! Try again later');
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const FetchPincodeDetailsApi = createAsyncThunk('karma/FetchPincodeDetailsApi', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.FetchPincodeDetails, params);
    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const completeKycUpdateApi = createAsyncThunk('karma/completeKycUpdateApi', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.completeKycUpdate, params);
    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});