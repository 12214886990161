import React, { useState, useEffect } from 'react';
import { Typography, Box, TextField, InputAdornment, IconButton, InputBase } from '@mui/material';
import PaperBox from 'components/PaperBox';
import { useDispatch, useSelector } from 'react-redux';
import Input from '@mui/material/Input';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { checkCKycStatusApi } from 'redux/kyc/kycApi';
import CustomSubmitButton from 'components/CustomSubmitButton';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { PATH_CKYC } from 'routes/paths';
import _ from 'lodash';
import { styled } from '@mui/material/styles';
import { isValidDate } from 'utils/common';
import { localStorageKey } from 'utils/constants/common';
import HelpComponent from 'pages/kyc/component/HelpComponent';
import useLocales from 'hooks/useLocales';
import videoConst from 'utils/constants/videoConst';
import useCommon from 'hooks/useComon';

const currDate = new Date();
const eighteenYearsAgo = currDate.setFullYear(currDate.getFullYear() - 18);
const etyFiveYrsAgo = currDate.setFullYear(currDate.getFullYear() - 85);

const RootStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  flex: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: '32px',
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const panReg = /^([A-Z]{5}[0-9]{4}[A-Z]{1})?$/;

function CKYCDashboardV2(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { pushGaEvent } = useCommon();
  const [dobField, setDobField] = useState({ value: '', error: '', touched: false, isFieldValid: false });
  const [panField, setPanField] = useState({ value: '', error: '', touched: false, isFieldValid: false });
  const [panInputMode, setPanInputMode] = useState('password');
  const { userProfile } = useSelector(({ dashboardReducer }) => dashboardReducer);

  useEffect(() => {
    if (userProfile?.dob && isValidDate(Number(userProfile?.dob))) {
      setDobField({ value: new Date(Number(userProfile?.dob)), error: '', touched: true, isFieldValid: true });
    }
  }, [userProfile]);

  const handleSubmit = async () => {
    pushGaEvent('ckyc_begin', { category: 'ekyc', action: 'click' });

    const mobile_number = localStorage.getItem(localStorageKey.phone);

    const params = {
      user_id: localStorage.getItem('user_id'),
      // id_type: 'PAN',
      id_number: panField.value,
      // date_of_birth: String(dob)

      date_of_birth: moment(dobField.value).format('DD-MM-YYYY')
    };
    if (!_.isEmpty(mobile_number)) {
      params.mobile_number = mobile_number.slice(-10);
    }

    dispatch(checkCKycStatusApi(params));
    // response = unwrapResult(response);

    navigate(PATH_CKYC.processing, { replace: true });
  };

  const handleInputChange = async (value, name) => {
    if (name === 'pan' && value.length < 11) {
      const panSpecChar = value.replace(/[^a-z0-9]/gi, '');
      const panCardNumUpCase = panSpecChar.toUpperCase();
      setPanField({ value: panCardNumUpCase, error: '', touched: true, isFieldValid: false });
    }
    if (name === 'dob') {
      // const date = new Date(value).getTime();
      const date = moment(value).format('l');
      // setDob(date);
      setDobField({ value: date, touched: true, error: '', isFieldValid: false });
      // validate();
    }
  };

  useEffect(() => {
    if (panField.value && panField.touched && panField.value.length === 10) {
      validate('pan');
    }
    if (dobField.value) {
      validate('dob');
    }
  }, [panField.value, dobField.value]);

  const validate = (field) => {
    const upPanField = { ...panField, isFieldValid: true };
    const upDobField = { ...dobField, isFieldValid: true };
    switch (field) {
      case 'pan':
        if (panField.value && !panField.value.match(panReg)) {
          upPanField.error = 'Please enter correct PAN number';
          upPanField.isFieldValid = false;
        }
        if (_.isEmpty(panField.value)) {
          upPanField.error = 'This is required field';
          upPanField.isFieldValid = false;
        }
        setPanField(upPanField);

        break;
      case 'dob':
        if (!dobField.value) {
          upDobField.error = 'This is required field';
          upDobField.isFieldValid = false;
        }
        setDobField(upDobField);
        break;
      default:
        break;
    }
  };

  const isFormValid = dobField.isFieldValid && panField.isFieldValid;

  // const getKeyboardType = () => {
  //   if (!panField.value) {
  //     return 'text';
  //   }
  //   const len = panField.value?.length;
  //   if (len < 5) {
  //     return 'text';
  //   }
  //   if (len >= 5 && len < 9) {
  //     return 'numeric';
  //   }
  //   return 'text';
  // };

  return (
    <RootStyle sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '12px',
            color: '#6F6F6F',
            letterSpacing: '.3px'
          }}
        >
          {translate('ckyc_tv_textViewCkyc2_label')}
        </Typography>
        <Box component="img" src="/ckycPan.svg" sx={{ mt: 2, paddingRight: '60px' }} />
        <Box sx={{ mt: 3, padding: '13px 0px 13px 27px', position: 'relative' }}>
          <Typography sx={{ fontSize: '12px', color: '#919191' }}>{translate('ckyc_tv_pan_label')}</Typography>
          <Input
            sx={{
              fontSize: '16px',
              color: 'transparent',
              // letterSpacing: panField.value ? '4.7px' : 'normal',
              mt: '4px',
              caretColor: 'black'
            }}
            classes={{
              label: { letterSpacing: 'normal' }
            }}
            placeholder="ABCDE1234F"
            // disableUnderline
            fullWidth
            onChange={(event) => handleInputChange(event.target.value, 'pan')}
            inputProps={{
              type: panInputMode,
              // inputMode: 'search',
              lang: 'en'
            }}
            onFocus={() => setPanInputMode('text')}
            onBlur={() => {
              validate('pan');
              setPanInputMode('password');
            }}
            value={panField.value}
          />
          <Typography sx={{ fontSize: '16px', color: 'black', top: '39px', position: 'absolute' }}>
            {panField.value}
          </Typography>

          {panField.touched && !_.isEmpty(panField?.error) && (
            <Typography
              sx={{
                fontSize: '10px',
                color: 'red',
                letterSpacing: '.3px'
              }}
            >
              {panField.error}
            </Typography>
          )}
        </Box>
        <Box sx={{ mt: 5, padding: '13px 20px 13px 27px' }}>
          <Typography sx={{ fontSize: '12px', color: '#919191' }}>{translate('ckyc_tv_dob_label')} </Typography>
          <MobileDatePicker
            value={dobField.value}
            maxDate={eighteenYearsAgo}
            // defaultValue={twentyFiveYearsAgo}
            showToolbar={false}
            disableFutures
            openTo="year"
            minDate={etyFiveYrsAgo}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              handleInputChange(newValue, 'dob');
            }}
            onClose={() => validate('dob')}
            renderInput={(params) => (
              <TextField
                sx={{
                  '& .MuiInput-underline:before': { borderBottomColor: 'rgba(145, 158, 171, 0.56) !important' },
                  '& .MuiInput-underline:after': { borderBottomColor: '#2EB67D !important' }
                }}
                variant="standard"
                fullWidth
                fontSize="16px"
                {...params}
                placeholder="DD-MMM-YYYY"
                InputProps={{
                  style: {
                    fontSize: '16px',
                    color: '#2EB67D'
                  },
                  endAdornment: (
                    <InputAdornment position="start" sx={{ mt: '-18px' }}>
                      <CalendarTodayIcon sx={{ color: '#2EB67D' }} />
                    </InputAdornment>
                  ),
                  disableUnderline: false
                }}
              />
            )}
          />
          {dobField.touched && !_.isEmpty(dobField.error) && (
            <Typography
              sx={{
                fontSize: '10px',
                color: 'red',
                letterSpacing: '.3px'
              }}
            >
              {dobField.error}
            </Typography>
          )}
        </Box>
        <HelpComponent videoId={videoConst?.ckyc_video_id} />
      </Box>
      <Box sx={{ width: '100%' }}>
        <CustomSubmitButton
          label={translate('transaction_dispute_button_dispute_submit_label')}
          disableBT={!isFormValid}
          onButtonPress={handleSubmit}
        />
      </Box>
    </RootStyle>
  );
}

export default CKYCDashboardV2;
