import { createSlice } from '@reduxjs/toolkit';
import { isAfterDate } from 'utils/common';
import { EWIstatusConst } from 'utils/constants/ewiConst';
import {
  getEasyInstalmentDashboardApi,
  instalmentChooseLoanAmountDetailApi,
  instalmentChooseLoanAmountDetailConfirmationApi,
  getInstalmentSchedulesApi,
  getInstalmentPlanDetailsApi,
  getInstalmentDeductionsAndRepaymentApi,
  getDueRepaymentDetailsApi,
  getInstalmentRepaymentDetailsApi,
  getPlanDetailsApi
} from './ewiApi';

import ewiDashboardMockRes from './mockResponse/ewiDashboard.json';

const initialState = {
  // businessSelected: {},
  ewiDashboardDetails: {},
  isEwiDashboardLoading: false,
  instalmentLoanAmountDetails: {},
  isInstalmentLoanAmountDetailLoading: false,
  instalmentChooseLoanAmountDetailConfirmDetails: {},
  instalmentChooseLoanAmountDetailConfirmLoading: false,
  loanAmountSelected: 0,
  instalmentSchedule: [],
  instalmentPlanDetails: {},
  instalmentData: {},
  instalmentDeductionsAndRepaymentDetails: {},
  nbfcCheckInitial: true,
  planDetails_to_display: {}
};

const slice = createSlice({
  name: 'ewi',
  initialState,
  reducers: {
    setLoanAmount: (state, action) => {
      state.loanAmountSelected = action.payload;
    },
    setEwiInstalmentData: (state, action) => {
      state.instalmentData = action.payload;
    },
    setNBFCCheck: (state, action) => {
      state.nbfcCheckInitial = action.payload.checkedNBFC;
    }
  },
  extraReducers: {
    [getEasyInstalmentDashboardApi.pending]: (state, action) => {
      state.isEwiDashboardLoading = true;
    },
    [getEasyInstalmentDashboardApi.fulfilled]: (state, action) => {
      const response = { ...action.payload };
      // const response = ewiDashboardMockRes.whenLoanCompleteUsedWellGraphScreen;
      const { ei } = response;
      if (ei) {
        response.isShowGraphScreen = ei.status === EWIstatusConst?.EIStarted;
      }
      if (ei?.status === 'EINotUsed') {
        response.isAmountUsed = false;
      } else {
        response.isAmountUsed = true;
      }
      //   ei.amountUnused = loan_details?.amount_unused;

      state.ewiDashboardDetails = response;
      state.isEwiDashboardLoading = false;
    },
    [getEasyInstalmentDashboardApi.rejected]: (state, action) => {
      state.isEwiDashboardLoading = false;
    },
    [instalmentChooseLoanAmountDetailApi.pending]: (state, action) => {
      state.isInstalmentLoanAmountDetailLoading = true;
    },
    [instalmentChooseLoanAmountDetailApi.fulfilled]: (state, action) => {
      state.isInstalmentLoanAmountDetailLoading = false;
      state.instalmentLoanAmountDetails = action.payload;
    },
    [instalmentChooseLoanAmountDetailApi.rejected]: (state, action) => {
      state.isInstalmentLoanAmountDetailLoading = false;
    },
    [instalmentChooseLoanAmountDetailConfirmationApi.pending]: (state, action) => {
      state.instalmentChooseLoanAmountDetailConfirmLoading = true;
    },
    [instalmentChooseLoanAmountDetailConfirmationApi.fulfilled]: (state, action) => {
      state.instalmentChooseLoanAmountDetailConfirmLoading = false;
      state.instalmentChooseLoanAmountDetailConfirmDetails = action.payload;
    },
    [instalmentChooseLoanAmountDetailConfirmationApi.rejected]: (state, action) => {
      state.instalmentChooseLoanAmountDetailConfirmLoading = false;
    },
    [getInstalmentSchedulesApi.fulfilled]: (state, action) => {
      state.instalmentSchedule = action.payload;
    },
    [getInstalmentPlanDetailsApi.fulfilled]: (state, action) => {
      state.instalmentPlanDetails = action.payload;
    },
    [getPlanDetailsApi.fulfilled]: (state, action) => {
      state.planDetails_to_display = action.payload;
    },
    [getInstalmentDeductionsAndRepaymentApi.fulfilled]: (state, action) => {
      state.instalmentDeductionsAndRepaymentDetails = action.payload;
    }
  }
});

export const { setLoanAmount, setEwiInstalmentData, setNBFCCheck } = slice.actions;
export default slice.reducer;
