import axios from 'utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import mockData from './mockResponse/ewiDashboard.json';

const urls = {
  getEasyInstalmentDashboard: '/credit/dashboard/GetEasyInstalmentDashboard',
  instalmentChooseLoanAmountDetail: 'credit/transaction/InstalmentChooseLoanAmountDetail',
  instalmentChooseLoanAmountDetailConfirmation: 'credit/transaction/InstalmentChooseLoanAmountDetailConfirmation',
  getInstalmentSchedules: 'credit/instalment/GetInstalmentSchedules',
  getInstalmentPlanDetails: 'credit/instalment/GetInstalmentPlanDetails',
  getInstalmentDeductionsAndRepayment: 'credit/instalment/GetInstalmentDeductionsAndRepayment',
  getDueRepaymentDetailsUrl: 'credit/repayment/GetDueRepaymentDetails',
  getRepaymentDetailsUrl: 'credit/repayment/GetRepaymentDetails',
  getEiRepaymentDetailsForeclosureUrl: 'credit/repayment/GetEiRepaymentDetailsForeclosure',
  getPlanDetailsApiURL: 'credit/plans/GetPlanDetailsForApp'
};

export const getEasyInstalmentDashboardApi = createAsyncThunk(
  'karma/getEasyInstalmentDashboard',
  async (params, thunkApi) => {
    try {
      const response = await axios.post(urls.getEasyInstalmentDashboard, params);

      if (response.data) {
        return response.data;
      }
      // mocking response
      // await setTimeout(() => {}, 2000);
      // const res = mockData.whenLoanCompleteUsedWellDoneScreen;
      // return res;
      // return thunkApi.rejectWithValue('Something went wrong with this');
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const instalmentChooseLoanAmountDetailApi = createAsyncThunk(
  'karma/instalmentChooseLoanAmountDetail',
  async (params, thunkApi) => {
    try {
      const response = await axios.post(urls.instalmentChooseLoanAmountDetail, params);

      if (response.data) {
        return response.data;
      }
      return thunkApi.rejectWithValue('There was an issue, please try later');
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const instalmentChooseLoanAmountDetailConfirmationApi = createAsyncThunk(
  'karma/instalmentChooseLoanAmountDetailConfirmation',
  async (params, thunkApi) => {
    try {
      const response = await axios.post(urls.instalmentChooseLoanAmountDetailConfirmation, params);

      if (response.data) {
        return response.data;
      }
      return thunkApi.rejectWithValue('There was an issue, please try later');
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getInstalmentSchedulesApi = createAsyncThunk('karma/getInstalmentSchedules', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.getInstalmentSchedules, params);

    if (response.data && response.data?.instalment_schedules) {
      return response.data?.instalment_schedules;
    }
    return thunkApi.rejectWithValue('There was an issue, please try later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getInstalmentDeductionsAndRepaymentApi = createAsyncThunk(
  'karma/getInstalmentDeductionsAndRepayment',
  async (params, thunkApi) => {
    try {
      const response = await axios.post(urls.getInstalmentDeductionsAndRepayment, params);

      if (response.data && response.data?.instalment_schedules) {
        return response.data?.instalment_schedules;
      }
      return thunkApi.rejectWithValue('There was an issue, please try later');
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPlanDetailsApi = createAsyncThunk('karma/getPlanDetailsApi', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.getPlanDetailsApiURL, params);

    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('There was an issue, please try later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getInstalmentPlanDetailsApi = createAsyncThunk(
  'karma/getInstalmentPlanDetailsApi',
  async (params, thunkApi) => {
    try {
      const response = await axios.post(urls.getInstalmentPlanDetails, params);

      if (response.data) {
        return response.data;
      }
      return thunkApi.rejectWithValue('There was an issue, please try later');
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getInstalmentRepaymentDetailsApi = createAsyncThunk(
  'karma/getInstalmentRepaymentDetailsApi',
  async (params, thunkApi) => {
    try {
      const response = await axios.post(urls.getRepaymentDetailsUrl, params);

      if (response.data) {
        return response.data;
      }
      return thunkApi.rejectWithValue('There was an issue, please try later');
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getDueRepaymentDetailsApi = createAsyncThunk('karma/getDueRepaymentDetails', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.getDueRepaymentDetailsUrl, params);

    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('There was an issue, please try later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getFullRepaymentDetailsForeclosureApi = createAsyncThunk(
  'karma/getFullRepaymentDetailsForeclosureApi',
  async (params, thunkApi) => {
    try {
      const response = await axios.post(urls.getEiRepaymentDetailsForeclosureUrl, params);

      if (response.data) {
        return response.data;
      }
      return thunkApi.rejectWithValue('There was an issue, please try later');
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
