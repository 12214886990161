export const onboardFailuresConst = {
  KYC_VERIFICATION: 'KYC_VERIFICATION',
  BANK_FAIURE: 'BANK_ACCOUNT_VERIFICATION'
};

export const ONBOARD_ITEM_STATUS = {
  DONE: 'DONE',
  NOT_DONE: 'NOT_DONE',
  NOT_REQUIRED: 'NOT_REQUIRED',
  ERROR: 'ERROR'
};

export const ONBOARD_STEPS = {
  BANK_ACCOUNT_SETUP: 'BANK_ACCOUNT_SETUP',
  PLAN_UNLOCK: 'PLAN_UNLOCK',
  KYC: 'KYC',
  REPAYMENT_AUTO_COLLECT_SETUP: 'REPAYMENT_AUTO_COLLECT_SETUP'
};
