import PropTypes from 'prop-types';
import { Form, FormikProvider } from 'formik';
import { makeStyles } from '@mui/styles';
// material
import { styled } from '@mui/material/styles';
import { Stack, Checkbox, TextField, Typography, Box, AppBar, Toolbar } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CustomSubmitButton from 'components/CustomSubmitButton';
// routes
// hooks
import useLocales from 'hooks/useLocales';
import usePartner from 'hooks/usePartner';
// ----------------------------------------------------------------------

const useStyles = makeStyles({
  inputBox: {
    fontWeight: 700,
    textAlign: 'center',
    fontSize: 22,
    letterSpacing: 1
  }
});

const BottomBar = styled(AppBar)(({ theme }) => ({
  top: 'auto',
  bottom: 0,
  backgroundColor: 'inherit',
  height: 'min-content'
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: '80px',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '0px',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    minHeight: 'min-content',
    // maxWidth: '90vw',
    margin: 'auto'
  }
}));
LoginForm.propTypes = {
  toggleLangDialog: PropTypes.func,
  formik: PropTypes.any
};
export default function LoginForm(props) {
  const { toggleLangDialog, formik } = props;
  const { translate } = useLocales();
  const { checkIfPartnerPhoneDisabled } = usePartner();
  const classes = useStyles();
  const { getPartnerWelcomeImg } = usePartner();

  const inputNumber = (event) => {
    const { value } = event?.target;
    const specialCharsRegex = /^([0-9])*$/;
    // if (value === '') {
    //   formik.setFieldValue('phone', value);
    // }
    // const finVal = value[value.length - 1];
    if (value.length <= 10 && specialCharsRegex.test(value)) {
      formik.setFieldValue('phone', value);
      if (formik.values.phone.length === 10 && formik.values.phone.length > value.length) {
        formik.setFieldTouched('phone', true, true);
      }
    }
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <FormikProvider value={formik}>
      <Form
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%'
        }}
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
      >
        <Box sx={{ position: 'absolute', marginTop: '-24px', margin: 'auto', left: '15px', top: '15px' }}>
          <Box
            component="img"
            src="/static/icon/select-language.svg"
            sx={{ height: '25px' }}
            onClick={toggleLangDialog}
          />
        </Box>
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Box component="img" src="/klLogo.webp" sx={{ height: '30px' }} />
            <Typography sx={{ color: '#2F6852', fontWeight: 500, fontSize: '16px' }}>
              Your financial partner in times of need
            </Typography>
          </Box>

          <Box sx={{ margin: 'auto', mt: 2, height: '193px' }} component="img" src={getPartnerWelcomeImg()} />

          <Stack sx={{ mt: 3, px: 5.5 }} directon="column" justifyContent="center" alignItems="center">
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Typography
                sx={{ lineHeight: '19px', fontSize: '16px', color: '#444444', fontWeight: 500, marginRight: '5px' }}
              >
                +91
              </Typography>
              <TextField
                id="standard-basic"
                placeholder={translate('phone_verify_et_login_phone_number_hint')}
                variant="standard"
                disabled={checkIfPartnerPhoneDisabled()}
                autoComplete="off"
                // type="number"
                {...getFieldProps('phone')}
                onChange={inputNumber}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && translate(errors.phone)}
                InputProps={{
                  className: classes.inputBox
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  inputMode: 'numeric',
                  style: { textAlign: 'center' },
                  autoComplete: 'new-amigo-number',
                  pattern: '[0-9]{10}',
                  form: {
                    autoComplete: 'off'
                  }
                }}
              />
              {values.phone?.length === 10 && !(touched.phone && errors.phone) && (
                <CheckCircleIcon sx={{ fontSize: 16 }} color="primary" />
              )}
            </Stack>

            <Typography
              sx={{
                fontSize: '12px',
                color: 'black',
                textAlignLast: 'center',
                fontWeight: 500,
                mt: 2,
                textAlign: 'center'
              }}
            >
              {translate('phone_verify_textView111_label')}
              {/* We will send SMS with 6-digit verification code on your mobile number. */}
            </Typography>
          </Stack>
        </Box>
        <BottomBar sx={{ width: '100%' }} position="relative" elevation={0}>
          <ToolbarStyle>
            <Box
              as="div"
              sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
              onClick={() =>
                formik.setFieldValue('is_allowed_to_contact_on_whatsapp', !values.is_allowed_to_contact_on_whatsapp)
              }
            >
              <Checkbox checked={values.is_allowed_to_contact_on_whatsapp} color="success" />
              <Typography sx={{ fontSize: '10px', color: '#525252', lineHeight: '16px' }}>
                {translate('phone_verify_cb_whatsapp_consent_check_label')}
              </Typography>
            </Box>
            <CustomSubmitButton
              label={translate('onion_login_button_sign_in_label')}
              loadBt={isSubmitting}
              type="submit"
              disableBT={!(values.phone.length === 10)}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 1,
                padding: '5px 10px',
                borderRadius: '4px',
                border: '1px solid #C0C0C0'
              }}
            >
              <Box component="img" src="/iso.webp" sx={{ height: '20px' }} />
              <Typography sx={{ fontSize: '12px', color: '#363636', fontWeight: 600, lineHeight: '14px', ml: 1 }}>
                ISO 27001: 2022
              </Typography>
            </Box>
            <Box
              sx={{
                textAlign: 'left',
                alignSelf: 'flex-start',
                position: 'absolute',
                left: 0,
                bottom: 0
              }}
            >
              {process.env.REACT_APP_BUILD_BRANCH && (
                <Typography
                  sx={{
                    fontSize: '10px',
                    color: '#919191',
                    fontWeight: 500,
                    maxWidth: '80px',
                    overflowWrap: 'anywhere'
                  }}
                >
                  {process.env.REACT_APP_BUILD_BRANCH}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                textAlign: 'right',
                alignSelf: 'flex-end',
                position: 'absolute',
                right: 0,
                bottom: 0
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  color: '#919191',
                  fontWeight: 500,
                  lineHeight: '14px',
                  position: 'absolute',
                  margin: 'auto',
                  right: 0,
                  bottom: 0
                }}
              >
                v{process.env.REACT_APP_VERSION_NAME}({process.env.REACT_APP_VERSION_CODE})
              </Typography>
            </Box>
          </ToolbarStyle>
        </BottomBar>
      </Form>
    </FormikProvider>
  );
}
