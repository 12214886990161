import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { getUserKycStatus } from 'redux/kyc/kycApi';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
// material
import { styled } from '@mui/material/styles';
//
import useIsMountedRef from 'hooks/useIsMountedRef';
import KycNavbar from './KycNavbar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
}));
const APP_BAR_MOBILE = 64;
const MainStyle = styled('div')(() => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingLeft: 24,
  paddingRight: 24,
  paddingTop: APP_BAR_MOBILE
}));

// ----------------------------------------------------------------------

export default function KycLayout() {
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (isMountedRef.current) dispatch(getUserKycStatus());
  }, []);

  return (
    <>
      <RootStyle>
        <KycNavbar />

        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
    </>
  );
}
