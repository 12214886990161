import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useLocales from 'hooks/useLocales';
import { localStorageKey } from 'utils/constants/common';

// material
import { styled } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Stack,
  Menu,
  MenuItem,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog,
  Button
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { LOADING, ONBOARD_SETUP, PATH_CKYC } from 'routes/paths';
import { ckycHelpConst } from 'utils/constants/kycConst';
import useAuth from 'hooks/useAuth';
import { whatsApp } from '../../config';
// components
//

// import NotificationsPopover from './NotificationsPopover';
// import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger ? 2 : 0,
    sx: { backgroundColor: trigger ? 'white' : 'transparent', transition: 'all 0.2s ease' }
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

const RootStyle = styled(AppBar)(({ theme }) => ({
  // backgroundColor: 'transparent',
  // boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
  height: 64
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  justifyContent: 'space-between',
  padding: '0px 24px'
}));

// ----------------------------------------------------------------------

CkycNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function CkycNavbar(props) {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openLogoutDialog, setLogoutDialog] = React.useState(false);
  const { logout } = useAuth();

  const open = Boolean(anchorEl);
  const { pathname } = useLocation();
  // const pathName = window.location.pathname?.split('/').pop();

  const onMenuBt = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onBtLogout = () => {
    setLogoutDialog(true);
  };

  const closeLogoutDialog = () => {
    setLogoutDialog(false);
  };

  const onBtSupport = () => {
    const phone = localStorage.getItem(localStorageKey.phone);
    let whatsAppUrl = `${whatsApp.url}/${whatsApp.number}`;
    const text = ckycHelpConst.CKYC;
    handleMenuClose();

    whatsAppUrl = whatsAppUrl.concat(`?text=${encodeURI(`${text}\n${phone}`)}`);
    window.open(whatsAppUrl, '_blank').focus();
  };

  const getHeader = () => {
    const header = translate('ckyc_tootbar_tv_title');

    return header;
  };

  const handleBackClick = () => {
    switch (pathname) {
      case PATH_CKYC.root:
        navigate(LOADING, { replace: true });
        break;
      case PATH_CKYC.documentsVerification:
        navigate(LOADING, { replace: true });
        break;
      case PATH_CKYC.postalCodeVerify:
        navigate(LOADING, { replace: true });
        break;
      case PATH_CKYC.fail:
        navigate(ONBOARD_SETUP, { replace: true });
        break;
      default:
        navigate(LOADING, { replace: true });
        break;
    }
  };

  return (
    <>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        anchorEl={anchorEl}
        open={open}
        dense
        onClose={handleMenuClose}
        // anchorOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left'
        // }}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left'
        // }}
      >
        <MenuItem onClick={onBtSupport}>{translate('common_contact_support_label')} </MenuItem>
        <MenuItem onClick={onBtLogout}>{translate('common_logout')} </MenuItem>
      </Menu>
      <Dialog
        open={openLogoutDialog}
        onClose={closeLogoutDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{translate('logout_dialog_title')} </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={logout} sx={{ color: 'red' }}>
            {translate('logout_dialog_yes')}
          </Button>
          <Button onClick={closeLogoutDialog}>{translate('logout_dialog_no')}</Button>
        </DialogActions>
      </Dialog>
      <ElevationScroll {...props}>
        <RootStyle position="fixed">
          <ToolbarStyle>
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
              <IconButton edge="start" aria-label="back arrow" sx={{ mr: 1, color: 'black' }} onClick={handleBackClick}>
                <ArrowBackIcon />
              </IconButton>
              <Typography sx={{ color: 'black', fontWeight: 500, fontSize: 20 }} component="div">
                {getHeader()}
              </Typography>
            </Stack>

            <IconButton edge="start" aria-label="help" sx={{ color: 'black' }} onClick={onMenuBt}>
              <HelpOutlineIcon />
            </IconButton>
          </ToolbarStyle>
        </RootStyle>
      </ElevationScroll>
    </>
  );
}
