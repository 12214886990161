import { Box, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import React from 'react';
import { MISSING_KYC } from 'utils/constants/kycConst';

function BAImgComp(props) {
  const { misMatchType = MISSING_KYC.NAME, aadharItem } = props;
  let imgUrl = aadharItem?.image_url;
  if (aadharItem?.image_url && aadharItem.item_sub_type === 'DIGILOCKER_AADHAAR') {
    imgUrl = `data:image/jpeg;base64,${imgUrl}`;
  }
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        border: '1.5px solid #E01E5A',
        borderRadius: '5px',
        mt: '20px',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'rgba(224, 30, 90, 0.05)'
      }}
    >
      <Box
        component="img"
        src={imgUrl || '/adhar_addProof.webp'}
        sx={{
          alignItems: 'center',
          textAlign: 'center',
          height: '165px'
        }}
      />
      {!imgUrl && (
        <Box
          sx={{
            position: 'absolute',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            border: '1.4px solid #E01E5A',
            padding: '5px',
            borderRadius: '5px',
            whiteSpace: 'nowrap',
            top: '60px',
            minWidth: '80px'
          }}
        >
          <ErrorIcon sx={{ position: 'absolute', ml: '88%', mt: '-13px', fontSize: '16px', color: '#E01E5A' }} />
          <Typography
            sx={{
              fontSize: '10px',
              fontWeight: '600',
              color: '#000',
              fontFamily: 'roboto'
            }}
          >
            {props?.name}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default BAImgComp;
