import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
//
import enLocales from './en.json';
import hiLocales from './hi.json';
import kannada from './kn.json';
import tamil from './ta.json';
import telugu from './te.json';
// ----------------------------------------------------------------------

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enLocales },
      // de: { login: login_de, verifyOtp: 'verifyOtp_en' },
      hi: { translations: hiLocales },
      kn: { translations: kannada },
      ta: { translations: tamil },
      te: { translations: telugu }
    },
    lng: localStorage.getItem('i18nextLng') || 'en',
    fallbackLng: 'en',
    debug: false,
    transSupportBasicHtmlNodes: true,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
