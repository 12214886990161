import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import Page from 'components/Page';
import CustomSubmitButton from 'components/CustomSubmitButton';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PaperBox from 'components/PaperBox';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import PersonIcon from '@mui/icons-material/Person';
import { LOADING, PATH_KYC } from 'routes/paths';
import useLocales from 'hooks/useLocales';
import useCommon from 'hooks/useComon';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingBottom: '32px'
}));

export default function CkycSuccessDetailsV2() {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const { pushGaEvent } = useCommon();

  const { ckycStatusDetails } = useSelector(({ kycReducer }) => kycReducer);

  return (
    <RootStyle title=" Complete CKYC | Karmalife">
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#F5F5F5',
            // position: 'relative',
            mt: 3,
            justifyContent: 'space-between',
            alignItems: '',
            padding: '16px 16px 16px 16px'
          }}
        >
          <Box
            component="img"
            src={`data:image/jpeg;base64,${ckycStatusDetails?.data?.photo}`}
            sx={{ height: '145px', width: '145px', margin: 'auto' }}
          />
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Stack direction="row" alignItems="center">
              <PersonIcon sx={{ width: '16px' }} />
              <Box sx={{ marginLeft: 1 }}>
                <Typography sx={{ fontSize: '10px', color: '#919191' }}>
                  {translate('ckyc_processing_tv_ckycTextView1_label')}
                </Typography>
                <Typography sx={{ fontSize: '16px', color: '#000000', width: '250px' }}>
                  {ckycStatusDetails?.data?.name}
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center">
              <PersonIcon sx={{ width: '16px' }} />
              <Box sx={{ marginLeft: 1 }}>
                <Typography sx={{ fontSize: '10px', color: '#919191' }}>
                  {translate('ckyc_processing_tv_ckycTextView2_label')}
                </Typography>
                <Typography sx={{ fontSize: '16px', color: '#000000' }}>
                  {ckycStatusDetails?.data?.father_name}
                </Typography>
              </Box>
            </Stack>
            {/* <Stack>
              <Typography sx={{ fontSize: '10px', color: '#919191' }}>{translate('common_dob')} </Typography>
              <Typography sx={{ fontSize: '16px', color: '#000000' }}>{ckycStatusDetails?.dob}</Typography>
            </Stack> */}
            {/* <Stack>
              <Typography sx={{ fontSize: '10px', color: '#919191' }}>{translate('common_gender_label')} </Typography>
              <Typography sx={{ fontSize: '16px', color: '#000000' }}>{ckycStatusDetails?.gender}</Typography>
            </Stack> */}
            <Stack direction="row" alignItems="center">
              <FingerprintIcon sx={{ width: '16px' }} />
              <Box sx={{ marginLeft: 1 }}>
                <Typography sx={{ fontSize: '10px', color: '#919191' }}>
                  {translate('ckyc_processing_tv_ckycTextView3_label')}
                </Typography>
                <Typography sx={{ fontSize: '16px', color: '#000000' }}>
                  {ckycStatusDetails?.data?.ckyc_number}
                </Typography>
              </Box>
            </Stack>

            <Stack direction="row" alignItems="center">
              <HomeIcon sx={{ width: '16px' }} />
              <Box sx={{ marginLeft: 1 }}>
                <Typography sx={{ fontSize: '10px', color: '#919191' }}>
                  {translate('common_address_label')}{' '}
                </Typography>
                <Typography sx={{ fontSize: '16px', color: '#000000', lineHeight: '19px', letterSpacing: '.33px' }}>
                  {ckycStatusDetails?.data?.address}{' '}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Box>

      <CustomSubmitButton
        label={translate('common_next')}
        type="submit"
        onButtonPress={() => {
          pushGaEvent('ckyc_details_confirm', { category: 'ekyc', action: 'click' });

          navigate(LOADING, { replace: true });
        }}
      />
    </RootStyle>
  );
}
