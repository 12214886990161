import React from 'react';
import { Box } from '@mui/material';

function PanBannerImg() {
  return (
    <>
      <Box
        component="img"
        src="/static/icon/panV2.webp"
        sx={{ mb: 3, width: '100%', px: '60px', textAlign: 'center', justifyContent: 'center', mt: 5 }}
      />
    </>
  );
}

export default PanBannerImg;
