import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import { Box, Stack, Typography, Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import useLocales from 'hooks/useLocales';

const HelpComponent = ({ videoId }) => {
  const { translate } = useLocales();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box component="div" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 3 }}>
        <Stack justifyContent="space-between" alignItems="" sx={{}}>
          <Typography
            sx={{
              fontSize: '12px',
              color: '#6F6F6F',
              lineHeight: '14px',
              letterSpacing: '.17px',
              mb: 1,
              textAlign: 'flexStart'
            }}
          >
            {translate('common_help')}
          </Typography>
          <Stack direction="row" justifyContent="space-between" alignItems="center" onClick={() => setOpen(true)}>
            <PlayCircleFilledRoundedIcon sx={{ color: '#2EB67D', width: '24px' }} />
            <Typography
              sx={{
                fontSize: '14px',
                color: '#2EB67D',
                fontWeight: 700,
                lineHeight: '16px',
                letterSpacing: '.2px',
                marginLeft: '8px'
              }}
            >
              {' '}
              {translate('common_watch')}
            </Typography>
          </Stack>
        </Stack>
      </Box>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <iframe
          width="100%"
          height="600"
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          frameBorder="0"
          // controls="0"
          allow="accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture"
          allowFullScreen
        />

        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
    </>
  );
};
HelpComponent.propTypes = {
  videoId: PropTypes.string
};

export default HelpComponent;
