import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { getUserKycStatus } from 'redux/kyc/kycApi';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
// material
import { styled } from '@mui/material/styles';

import useLocales from 'hooks/useLocales';
import useIsMountedRef from 'hooks/useIsMountedRef';
import InfoHeader from 'components/InfoHeader';
import KycNavbar from './KycNavbar';
import PanBottom from './PanBottom';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
}));
const APP_BAR_MOBILE = 64;
const MainStyle = styled('div')(() => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingLeft: 24,
  paddingRight: 24,
  // justifyContent: 'space-between',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: APP_BAR_MOBILE
}));

const BoxStyle = styled('div')(() => ({
  flexGrow: 1,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end'
}));

// ----------------------------------------------------------------------

export default function KycPanLayoutV2() {
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (isMountedRef.current) dispatch(getUserKycStatus());
  }, []);

  return (
    <>
      <RootStyle>
        <KycNavbar />
        <MainStyle>
          <InfoHeader subHeading={translate('kyc_dashboard_textView584_label')} />
          <Outlet />
          <BoxStyle>
            <PanBottom />
          </BoxStyle>
        </MainStyle>
      </RootStyle>
    </>
  );
}
