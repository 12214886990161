import { Box, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import React from 'react';
import { MISSING_KYC } from 'utils/constants/kycConst';

function BAImgComp(props) {
  const { misMatchType = MISSING_KYC.NAME, digilockerItem } = props;
  let imgUrl = digilockerItem?.image_url;
  if (digilockerItem?.image_url && digilockerItem.item_sub_type === 'DIGILOCKER_AADHAAR') {
    imgUrl = `data:image/jpeg;base64,${imgUrl}`;
  }
  return (
    <Box
      sx={{
        width: '100%',
        border: '1.5px solid #E01E5A',
        borderRadius: '5px',
        mt: '20px',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'rgba(224, 30, 90, 0.05)'
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <Box
          component="img"
          src="/adhar_addProof.webp"
          sx={{
            alignItems: 'center',
            textAlign: 'center',
            height: '165px'
          }}
        />
        {imgUrl ? (
          <Box
            sx={{
              position: 'absolute',
              backgroundColor: 'rgba(255, 255, 255, 1)',
              border: '1.4px solid #E01E5A',
              // padding: '5px',
              borderRadius: '5px',
              whiteSpace: 'nowrap',
              top: '60px',
              left: '10px'
              // minWidth: '80px'
            }}
          >
            <Box
              component="img"
              src={imgUrl}
              sx={{
                alignItems: 'center',
                height: '60px',
                // position: 'absolute',
                textAlign: 'center'
              }}
            />
            {/* </Typography> */}
          </Box>
        ) : (
          <Box
            sx={{
              position: 'absolute',
              backgroundColor: 'rgba(255, 255, 255, 1)',
              border: '1.4px solid #E01E5A',
              padding: '6px',
              borderRadius: '5px',
              whiteSpace: 'nowrap',
              top: '60px',
              left: '90px',
              minWidth: '90px'
            }}
          >
            <ErrorIcon sx={{ position: 'absolute', ml: '89%', mt: -2, fontSize: '16px', color: '#E01E5A' }} />
            <Typography
              sx={{
                fontSize: '10px',
                fontWeight: '600',
                color: '#000',
                fontFamily: 'roboto'
                // textAlign: 'center'
              }}
            >
              {props?.name}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default BAImgComp;
