import axios from 'utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createTransferParams } from 'utils/jwt';

const urls = {
  bankTransfer: 'credit/transfer/BankTransfer',
  pennyTransfer: 'common/user/BankAccountPennydropVerification',
  getOutstanding: 'credit/repayment/GetOutstanding',
  initiateSelfRepayment: 'credit/repayment/InitiateSelfRepayment',
  getTransaction: 'credit/transaction/GetTransaction',
  checkSelfRepaymentStatus: 'credit/repayment/CheckSelfRepaymentStatus',
  getAutopaySubscriptionDetailsURL: 'credit/autopay/GetAutoPaySubscriptioDetails',
  createAutopaySubscriptionDetailsURL: 'credit/autopay/CreateAutopaySubscription',
  syncRepaymentAutoCollectSubscriptionDetails: 'common/user/SyncRepaymentAutoCollectSubscriptionDetails'
};

export const initiateBankTransferApi = createAsyncThunk('karma/initiateBankTransfer', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.bankTransfer, await createTransferParams(params));

    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response?.data || 'Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const initiatePennyTransferApi = createAsyncThunk('karma/initiatePennyTransferApi', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.pennyTransfer, await createTransferParams(params));

    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response?.data || 'Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getOutstandingApi = createAsyncThunk('karma/getOutstandingApi', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.getOutstanding, params);
    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const initiateSelfRepaymentApi = createAsyncThunk('karma/initiateSelfRepaymentApi', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.initiateSelfRepayment, await createTransferParams(params));

    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response?.data || 'Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const checkSelfRepaymentStatusApi = createAsyncThunk(
  'karma/checkSelfRepaymentStatusApi',
  async (params, thunkApi) => {
    try {
      const response = await axios.post(urls.checkSelfRepaymentStatus, params);
      if (response.data) {
        return response.data;
      }
      return thunkApi.rejectWithValue('Error! Try again later');
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getTransactionApi = createAsyncThunk('karma/getTransactionApi', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.getTransaction, params);
    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getAutoPaySubscriptionDetailsApi = createAsyncThunk(
  '/karma/getAutoPaySubscriptionDetailsApi',
  async (params, thunkApi) => {
    try {
      const response = await axios.post(urls.getAutopaySubscriptionDetailsURL, { userid: params });
      if (response.data) {
        return response.data;
      }
      return thunkApi.rejectWithValue('Error! Try again later');
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const createAutoPaySubscriptionDetailsApi = createAsyncThunk(
  '/karma/createAutoPaySubscriptionDetailsApi',
  async (params, thunkApi) => {
    try {
      const response = await axios.post(urls.createAutopaySubscriptionDetailsURL, { userid: params });
      if (response.data) {
        return response.data;
      }
      return thunkApi.rejectWithValue('Error! Try again later');
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const syncRepaymentAutoCollectSubscriptionDetailsApi = createAsyncThunk(
  '/karma/syncRepaymentAutoCollectSubscriptionDetailsApi',
  async (params, thunkApi) => {
    try {
      const response = await axios.post(urls.syncRepaymentAutoCollectSubscriptionDetails, params);
      if (response.data) {
        return response.data;
      }
      return thunkApi.rejectWithValue('Error! Try again later');
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
