import { useTranslation } from 'react-i18next';
// material
import { enUS, hiIN } from '@mui/material/locale';
import axios from 'utils/axios';
import { localStorageKey } from 'utils/constants/common';

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS
  },
  {
    label: 'हिंदी',
    value: 'hi',
    systemValue: hiIN
  },
  {
    label: 'ಕನ್ನಡ',
    value: 'kn',
    systemValue: hiIN
  },
  {
    label: 'తెలుగు',
    value: 'te',
    systemValue: hiIN
  },
  {
    label: 'தமிழ்',
    value: 'ta',
    systemValue: hiIN
  }
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem(localStorageKey.i18nextLng);
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[0];

  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang);
    axios.defaults.headers['X-Language'] = newlang;
    localStorage.setItem(localStorageKey.isLangSelected, 'true');
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS
  };
}
