import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useLocales from 'hooks/useLocales';
import LogoutIcon from '@mui/icons-material/Logout';
import useAuth from 'hooks/useAuth';

// material
import { styled } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Stack,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  DialogContentText
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { PATH_KYC, PATH_LOC_DASHBOARD, PATH_BANK } from 'routes/paths';
// components
//

// import NotificationsPopover from './NotificationsPopover';
// import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger ? 2 : 0,
    sx: { backgroundColor: trigger ? 'white' : 'transparent', transition: 'all 0.2s ease' }
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

const RootStyle = styled(AppBar)(({ theme }) => ({
  // backgroundColor: 'transparent',
  // boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
  height: 64
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  justifyContent: 'space-between',
  padding: '0px 24px'
}));

// ----------------------------------------------------------------------

BankNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function BankNavbar(props) {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const pathName = window.location.pathname?.split('/').pop();
  const { logout } = useAuth();
  const [openLogoutDialog, setLogoutDialog] = React.useState(false);

  const onBtLogout = () => {
    setLogoutDialog(true);
  };

  const closeLogoutDialog = () => {
    setLogoutDialog(false);
  };

  // const getHeader = () => {
  //   let header = 'Complete your KYC';
  //   switch (pathName) {
  //     case 'address-verify':
  //       header = 'Aadhaar Verification';
  //       break;
  //     case 'bank-register':
  //       header = 'Bank Transfer';
  //       break;
  //     default:
  //       break;
  //   }
  //   return header;
  // };

  // const showHelpIcon = () => {
  //   switch (pathName) {
  //     case 'address-verify':
  //       return true;
  //     case 'bank-register':
  //       return false;
  //     default:
  //       return false;
  //   }
  // };

  const showLogoutIcon = () => {
    switch (pathName) {
      case 'bank-register':
        return true;
      default:
        return false;
    }
  };

  const handleBackClick = () => {
    switch (pathName) {
      case 'bank-register':
        navigate(-1);
        break;

      case 'penny-verification':
        navigate(PATH_BANK.register, { replace: true });
        break;

      case 'transfer':
        navigate(PATH_LOC_DASHBOARD.root, { replace: true });
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Dialog
        open={openLogoutDialog}
        onClose={closeLogoutDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{translate('logout_dialog_title')} </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={logout} sx={{ color: 'red' }}>
            {translate('logout_dialog_yes')}
          </Button>
          <Button onClick={closeLogoutDialog}>{translate('logout_dialog_no')}</Button>
        </DialogActions>
      </Dialog>
      <ElevationScroll {...props}>
        <RootStyle position="fixed">
          <ToolbarStyle>
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
              <IconButton edge="start" aria-label="back arrow" sx={{ mr: 1, color: 'black' }} onClick={handleBackClick}>
                <ArrowBackIcon />
              </IconButton>
              <Typography sx={{ color: 'black', fontWeight: 500, fontSize: 16 }} component="div">
                {translate('common_bank_transfer_label')}
              </Typography>
            </Stack>
            {/* {showHelpIcon() && (
              <IconButton edge="start" aria-label="help" sx={{ color: 'black' }}>
                <HelpOutlineIcon />
              </IconButton>
            )} */}
            {showLogoutIcon() && (
              <IconButton
                edge="start"
                aria-label="help"
                sx={{ color: 'black', paddingLeft: '12px' }}
                onClick={onBtLogout}
              >
                <LogoutIcon sx={{ fontSize: '20px', color: 'red' }} />
              </IconButton>
            )}
          </ToolbarStyle>
        </RootStyle>
      </ElevationScroll>
    </>
  );
}
