import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useLocales from 'hooks/useLocales';
import { localStorageKey } from 'utils/constants/common';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import useAuth from 'hooks/useAuth';

// material
import { styled } from '@mui/material/styles';
import { PATH_AUTH } from 'routes/paths';
import { signUpHelpConst } from 'utils/constants/kycConst';

import useScrollTrigger from '@mui/material/useScrollTrigger';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Stack,
  Menu,
  MenuItem,
  Dialog,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';
import { whatsApp } from '../../config';
//

// import NotificationsPopover from './NotificationsPopover';
// import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger ? 2 : 0,
    sx: { backgroundColor: trigger ? 'white' : 'transparent', transition: 'all 0.2s ease' }
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

const RootStyle = styled(AppBar)(({ theme }) => ({
  // backgroundColor: 'transparent',
  // boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
  height: 64
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  justifyContent: 'space-between',
  padding: '0px 24px'
}));

// ----------------------------------------------------------------------

SignUpNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function SignUpNavbar(props) {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openLogoutDialog, setLogoutDialog] = React.useState(false);
  const { logout } = useAuth();

  const open = Boolean(anchorEl);

  const onMenuBt = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onBtLogout = () => {
    setLogoutDialog(true);
  };

  const closeLogoutDialog = () => {
    setLogoutDialog(false);
  };

  const onBtSupport = () => {
    const phone = localStorage.getItem(localStorageKey.phone);
    let whatsAppUrl = `${whatsApp.url}/${whatsApp.number}`;
    let text = '';
    handleMenuClose();
    switch (pathname) {
      case PATH_AUTH.signUp:
        text = signUpHelpConst.SIGNUP;
        break;
      default:
        break;
    }
    whatsAppUrl = whatsAppUrl.concat(`?text=${encodeURI(`${text}\n${phone}`)}`);
    window.open(whatsAppUrl, '_blank').focus();
  };

  const showHelpIcon = () => {
    switch (pathname) {
      case PATH_AUTH.signUp:
        return true;
      default:
        return false;
    }
  };

  return (
    <>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        anchorEl={anchorEl}
        open={open}
        dense
        onClose={handleMenuClose}
        // anchorOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left'
        // }}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left'
        // }}
      >
        <MenuItem onClick={onBtSupport}>{translate('common_contact_support_label')} </MenuItem>

        <MenuItem onClick={onBtLogout}>{translate('common_logout')} </MenuItem>
      </Menu>
      <Dialog
        open={openLogoutDialog}
        onClose={closeLogoutDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{translate('logout_dialog_title')} </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={logout} sx={{ color: 'red' }}>
            {translate('logout_dialog_yes')}
          </Button>
          <Button onClick={closeLogoutDialog}>{translate('logout_dialog_no')}</Button>
        </DialogActions>
      </Dialog>
      <ElevationScroll {...props}>
        <RootStyle position="fixed">
          <ToolbarStyle>
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
              <Typography sx={{ color: 'black', fontWeight: 500, fontSize: 20 }} component="div">
                {translate('user_profile_toolbar_title')}
              </Typography>
            </Stack>
            {showHelpIcon() && (
              <Box>
                <IconButton edge="start" aria-label="help" sx={{ color: 'black' }} onClick={onMenuBt}>
                  <HelpOutlineIcon />
                </IconButton>
                {/* <IconButton
                  edge="start"
                  aria-label="help"
                  sx={{ color: 'black', paddingLeft: '12px' }}
                  onClick={handleClickOpen}
                >
                  <LogoutIcon sx={{ fontSize: '20px', color: 'red' }} />
                </IconButton> */}
              </Box>
            )}
          </ToolbarStyle>
        </RootStyle>
      </ElevationScroll>
    </>
  );
}
