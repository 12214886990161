import { Box, Button, Typography } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import _, { forEach, some } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { deleteKYCApi, getResetKycItems, listBankAccountApi } from 'redux/dashboard/userApi';
import { PATH_BANK, PATH_KYC } from 'routes/paths';
import { useNavigate } from 'react-router-dom';
import { delelteBankAccountApi } from 'redux/onboard/onboardApi';
import { localStorageKey } from 'utils/constants/common';
import { whatsApp } from 'config';
import { getOnboardDashboardApi } from 'redux/dashboard/dashboardApi';
import { MISSING_KYC } from 'utils/constants/kycConst';
import PanCardImgComp from './components/PanCardImgComp';
import DLImgComp from './components/DLImgComp';
import AdhaarImgComp from './components/AdhaarImgComp';
import DigiLockerImgComp from './components/DigilockerImgComp';
import BAImgComp from './components/BAImgComp';
import VIImgComp from './components/VIImgComp';

const FixNowPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fixNowComps, setFixnowComps] = useState([]);
  const [misMatchType, setMisMatchType] = useState('name');
  const { userKycDetails = {} } = useSelector(({ kycReducer }) => kycReducer);
  const { kyc_items } = userKycDetails;
  const { bankAccountList } = useSelector(({ dashboardReducer }) => dashboardReducer);
  const { availBankAcc } = bankAccountList;

  const user_id = localStorage.getItem('user_id');

  useEffect(() => {
    const params = {
      user_id
    };
    dispatch(getResetKycItems(params))
      .then(unwrapResult)
      .then((data) => {
        setFixnowComps(data?.items);
        let isSelfieMisMatch = false;
        let isNameMisMatch = false;
        forEach(data?.items, (item) => {
          if (item?.item_type === 'SELFIE') isSelfieMisMatch = true;

          if (item?.item_type === 'PAN' || item?.item_type === 'BANK_ACCOUNT') isNameMisMatch = true;
        });
        let missing = MISSING_KYC.NAME;
        if (isNameMisMatch && isSelfieMisMatch) missing = MISSING_KYC.BOTH;
        else if (isSelfieMisMatch) missing = MISSING_KYC.SELFIE;

        setMisMatchType(missing);
      });
  }, []);

  async function deleteBankAcc() {
    const params = {
      user_id,
      remove_all: true,
      remove_verifications_only: true
    };
    let updata = {};
    // navigate(PATH_BANK.register, { replace: true });
    dispatch(delelteBankAccountApi(params))
      .then(unwrapResult)
      .then(async (data) => {
        updata = data;
        let res = await dispatch(listBankAccountApi());
        res = unwrapResult(res);
        dispatch(getOnboardDashboardApi({}));
        // localStorage.setItem('acc_num', data?.account_number);
        navigate(PATH_BANK.register, { replace: true });
      })
      .catch((err) => console.log(err));
    // if (!_.isEmpty(updata)) {
    //   const res = await dispatch(listBankAccountApi());
    //   unwrapResult(res);
    // }
    console.log('deleted bank account');
  }

  const resetPanFlow = (name, value) => {
    if (name === 'BANK_ACCOUNT') {
      deleteBankAcc();
      return;
    }
    const params = {
      user_id,
      fields_to_delete: {
        delete_address_proof: false,
        delete_pan: false,
        delete_selfie: false
      }
    };
    let navItem = '';
    if (name === 'PAN') {
      params.fields_to_delete.delete_pan = value;
      // get the navigation
      const isPanDocEnabled =
        Array.isArray(kyc_items) &&
        kyc_items.length > 0 &&
        _.some(kyc_items[0]?.subItems, (item) => item.kyc_type === 'PAN_DOCUMENT');
      navItem = !isPanDocEnabled ? 'pancard' : 'pan-doc';
    } else if (name === 'ADDRESS_PROOF') {
      params.fields_to_delete.delete_address_proof = value;
      navItem = 'address-proof';
    } else {
      const isLiveSelfieEnabled = _.some(kyc_items, (item) => item.kyc_type === 'SELFIE_LIVE');
      navItem = !isLiveSelfieEnabled ? 'SELFIE_LIVE' : 'SELFIE';
      params.fields_to_delete.delete_selfie = value;
    }
    // handleNavigateTo(navItem);

    dispatch(deleteKYCApi(params))
      .then(unwrapResult)
      .then((data) => {
        handleNavigateTo(navItem);
      })
      .catch((err) => console.log(err));
  };

  function handleNavigateTo(navTo) {
    switch (navTo) {
      case 'pancard':
        navigate(PATH_KYC.pancard, { replace: true });
        break;
      case 'pan-doc':
        navigate(PATH_KYC.pan_doc, { replace: true });
        break;
      case 'SELFIE':
        navigate(PATH_KYC.selfie, { replace: true });
        break;
      case 'SELFIE_LIVE':
        navigate(PATH_KYC.liveSelfie, { replace: true });
        break;
      case 'address-proof':
        navigate(PATH_KYC.addressDashboard, { replace: true });
        break;
      default:
        navigate(PATH_KYC.root);
        break;
    }
    // console.log(flow_name);
    if (navTo === 'pancard') {
      navigate(PATH_KYC.pancard, { replace: true });
    }
  }
  const onBtSupport = () => {
    window.history.back();
    // toggleButton(!button);
    const phone = localStorage.getItem(localStorageKey.phone);
    let whatsAppUrl = `${whatsApp.url}/${whatsApp.number}`;
    whatsAppUrl = whatsAppUrl.concat(`?text=${encodeURI(`Help Reset KYC\n${phone}`)}`);
    window.open(whatsAppUrl, '_blank').focus();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
      <Box>
        <Typography sx={{ fontSize: '14px', lineHeight: '16px', fontWeight: '600' }}>
          Please correct the following errors to proceed
        </Typography>
        {Array.isArray(fixNowComps) &&
          fixNowComps.map((data, ind) => (
            <Box
              key={ind}
              sx={{
                display: 'flex',
                padding: '16px',
                borderRadius: '8px',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#F0F0F0',
                flexDirection: 'column',
                width: '100%',
                mt: 3
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Box sx={{ width: '60%' }}>
                  <Typography sx={{ fontSize: '16px', ineHeight: '18px', fontWeight: '700', color: '#363636' }}>
                    {data?.title}
                  </Typography>
                  <Typography sx={{ fontSize: '14px', ineHeight: '16px', fontWeight: '500', color: '#6F6F6F' }}>
                    {data?.description}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    onClick={() => resetPanFlow(data?.item_type, true)}
                    // label="FIX NOW"
                    sx={{
                      fontWeight: 'bold',
                      height: '40px',
                      fontSize: '0.875rem',
                      backgroundColor: '#E01E5A',
                      padding: '14px 28px',
                      borderRadius: '8px',
                      color: 'white',
                      border: 'none',
                      my: 2
                    }}
                    // variant='contained'
                    color="secondary"
                  >
                    {data?.action}
                  </Button>
                </Box>
              </Box>
              {data.item_type === 'PAN' && <PanCardImgComp panItem={data} name={data?.name} />}
              {data.item_type === 'ADDRESS_PROOF' && data?.item_sub_type === 'DOC_DRIVING_LICENSE' && (
                <DLImgComp name={data?.name} dlItem={data} />
              )}
              {data.item_type === 'ADDRESS_PROOF' && data?.item_sub_type === 'DOC_AADHAAR' && (
                <AdhaarImgComp aadharItem={data} name={data?.name} misMatchType={misMatchType} />
              )}
              {data.item_type === 'ADDRESS_PROOF' && data?.item_sub_type === 'DIGILOCKER_AADHAAR' && (
                <DigiLockerImgComp digilockerItem={data} name={data?.name} misMatchType={misMatchType} />
              )}
              {data.item_type === 'SELFIE' && (
                <Box
                  sx={{
                    position: 'relative',
                    border: '1.5px solid #E01E5A',
                    borderRadius: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    mt: '20px',
                    width: '100%',
                    backgroundColor: 'rgba(224, 30, 90, 0.05)'
                  }}
                >
                  <Box
                    component="img"
                    src={data?.image_url}
                    sx={{
                      alignItems: 'center',
                      textAlign: 'center',
                      height: '145px'
                    }}
                  />
                </Box>
              )}
              {data.item_type === 'ADDRESS_PROOF' && data?.item_sub_type === 'DOC_EPIC' && (
                <VIImgComp name={data?.name} voterItem={data} />
              )}
              {data.item_type === 'BANK_ACCOUNT' && (
                <BAImgComp name={data?.name} accData={data} acc_num={availBankAcc?.account_number} />
              )}
            </Box>
          ))}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', my: 2 }}>
        <Typography sx={{ color: 'rgba(62, 62, 62, 0.56)', fontSize: '12px', fontWeight: '400', mr: '5px' }}>
          If you are having trouble, please{' '}
        </Typography>
        <Typography
          onClick={onBtSupport}
          style={{ textDecoration: 'underline', color: '#2EB67D', fontSize: '14px', fontWeight: '700' }}
        >
          contact support
        </Typography>
      </Box>
    </Box>
  );
};

export default FixNowPage;
