import { useState, useEffect } from 'react';
import NProgress from 'nprogress';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import _ from 'lodash';
// material
import { styled } from '@mui/material/styles';
import { getLOCDashboardApi, getOnboardDashboardApi } from 'redux/dashboard/dashboardApi';
import { getUserProfileInfoApi } from 'redux/dashboard/userApi';
//
import useAuth from 'hooks/useAuth';
import { PATH_AUTH, PATH_LOC_DASHBOARD } from 'routes/paths';
import { appendSearchParams } from 'utils/common';
import DashboardNavbar from './DashboardNavbar';
// import DashboardSidebar from './DashboardSidebar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  maxHeight: '100vh',
  overflow: 'hidden'
  // [theme.breakpoints.up('md')]: {
  //   maxWidth: '90vw',
  //   margin: 'auto'
  // }
}));

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingLeft: 24,
  paddingRight: 24
  // paddingTop: APP_BAR_MOBILE,
  // paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------
// let isApiHit = false;
export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { onboardDashboardDetails, isDashboardPageLoading, userProfile } = useSelector(
    ({ dashboardReducer }) => dashboardReducer
  );
  const { isPlanPageLoading, availablePlanList } = useSelector(({ planReducer }) => planReducer);
  const { is_onboard_completed } = onboardDashboardDetails;

  useEffect(() => {
    if (_.isEmpty(onboardDashboardDetails)) dispatch(getOnboardDashboardApi({}));
    if (_.isEmpty(userProfile)) dispatch(getUserProfileInfoApi({}));
    if (!_.includes(pathname, PATH_LOC_DASHBOARD.home)) {
      dispatch(getLOCDashboardApi({}));
      // isApiHit = true;
    }
  }, []);

  useEffect(() => {
    if (isDashboardPageLoading || isPlanPageLoading) {
      NProgress.start();
    } else {
      NProgress.done();
    }

    return () => {
      NProgress.remove();
    };
  }, [isDashboardPageLoading, isPlanPageLoading]);

  useEffect(() => {
    if (pathname && _.includes(pathname, PATH_LOC_DASHBOARD.history)) {
      // navigate(PATH_LOC_DASHBOARD.history);
      return;
    }
    if (pathname && _.includes(pathname, PATH_LOC_DASHBOARD.support)) {
      return;
    }

    if (!_.isEmpty(onboardDashboardDetails) && !is_onboard_completed) {
      navigate(appendSearchParams('/'), { replace: true });
    }

    if (_.isEmpty(userProfile) && _.isEmpty(availablePlanList)) {
      return;
    }
    if (!_.isEmpty(userProfile) && !userProfile?.is_user_first_update_done) {
      navigate(appendSearchParams(PATH_AUTH.signUp), { replace: true });
      return;
    }
    if (
      !_.isEmpty(availablePlanList) &&
      // availablePlanList?.isPlanAvailable &&
      !availablePlanList?.isSubscribedToAnyPlan
    ) {
      navigate(appendSearchParams(PATH_LOC_DASHBOARD.creditOffer), { replace: true });
    } else {
      navigate(appendSearchParams(PATH_LOC_DASHBOARD.home), { replace: true });
    }
  }, [availablePlanList, userProfile, pathname]);

  return (
    <>
      <RootStyle>
        {/* <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} /> */}
        <MainStyle>
          <Outlet />
        </MainStyle>
        {!isDashboardPageLoading && !isPlanPageLoading && <DashboardNavbar onOpenSidebar={() => setOpen(true)} />}
      </RootStyle>
      {/* <FooterNav />
      <Footer /> */}
    </>
  );
}
