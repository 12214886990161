import React, { useState } from 'react';
import { Box, FormControl, InputLabel, Input, InputAdornment, FormHelperText, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PropTypes from 'prop-types';
import _ from 'lodash';
import useLocales from 'hooks/useLocales';

function PanCardInput(props) {
  const { translate } = useLocales();
  const [panInputMode, setPanInputMode] = useState('password');

  return (
    <Box sx={{ position: 'relative' }}>
      <Typography sx={{ fontSize: '12px', color: '#919191' }}>PAN Number</Typography>
      <Input
        sx={{
          fontSize: '16px',
          color: 'transparent',
          // letterSpacing: panField.value ? '4.7px' : 'normal',
          mt: '4px',
          caretColor: 'black'
        }}
        classes={{
          label: { letterSpacing: 'normal' }
        }}
        placeholder="ABCDE1234F"
        // disableUnderline
        fullWidth
        onChange={props.handleChange}
        inputProps={{
          type: panInputMode,
          // inputMode: 'search',
          lang: 'en'
        }}
        autoFocus
        onFocus={() => setPanInputMode('text')}
        onBlur={() => {
          setPanInputMode('password');
        }}
        value={props.value}
      />
      <Typography sx={{ fontSize: '16px', color: 'black', top: '27px', position: 'absolute' }}>
        {props.value}
      </Typography>

      {!_.isEmpty(props?.error) && (
        <Typography
          sx={{
            fontSize: '10px',
            color: 'red',
            letterSpacing: '.3px'
          }}
        >
          {props.err}
        </Typography>
      )}
      {/* <Typography sx={{ fontSize: '12px', color: '#424242', marginTop: '20px' }}>
        <span style={{ color: '#363636', fontWeight: 700 }}>PAN Name</span> should be the same as your
        <span style={{ color: '#363636', fontWeight: 700 }}> Bank A/C Name</span>
      </Typography> */}
    </Box>
  );
}
PanCardInput.propTypes = {
  value: PropTypes.string,
  err: PropTypes.string,
  handleChange: PropTypes.func
};

export default PanCardInput;
