import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
// material
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { AppBar, Icon } from '@mui/material';
import { PATH_LOC_DASHBOARD } from 'routes/paths';
import useLocales from 'hooks/useLocales';

// components
//

// import NotificationsPopover from './NotificationsPopover';
// import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 68;

const RootStyle = styled(AppBar)(() => ({
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  top: 'auto',
  bottom: 0,
  backgroundColor: 'white',
  boxShadow: '0 0 19px 0 rgba(0,0,0,0.05)',
  height: 64
}));

const useStyles = makeStyles(() => ({
  navItemBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.4,
    padding: '0 12px',
    textDecoration: 'none',
    '&:hover': {
      opacity: 1
    }
  },
  navItemName: {
    fontSize: 13
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar() {
  const { pathname } = useLocation();
  const { translate } = useLocales();
  const navigate = useNavigate();

  return (
    <>
      <RootStyle position="fixed">
        <BottomNavigation showLabels>
          <BottomNavigationAction
            key="nav1"
            label={translate('title_home')}
            onClick={() => navigate(PATH_LOC_DASHBOARD.home)}
            icon={
              <Icon sx={{ color: _.isEqual(pathname, PATH_LOC_DASHBOARD.home) ? 'primary.main' : '#919191' }}>
                home_icon
              </Icon>
            }
          />
          <BottomNavigationAction
            key="nav2"
            label={translate('title_history')}
            onClick={() => navigate(PATH_LOC_DASHBOARD.history)}
            icon={
              <Icon sx={{ color: _.isEqual(pathname, PATH_LOC_DASHBOARD.history) ? 'primary.main' : '#919191' }}>
                {' '}
                swap_horiz_icon
              </Icon>
            }
          />
          <BottomNavigationAction
            key="nav3"
            label={translate('title_settings')}
            onClick={() => navigate(PATH_LOC_DASHBOARD.support)}
            icon={
              <Icon sx={{ color: _.isEqual(pathname, PATH_LOC_DASHBOARD.support) ? 'primary.main' : '#919191' }}>
                {' '}
                more_horiz_icon
              </Icon>
            }
          />
        </BottomNavigation>
      </RootStyle>
    </>
  );
}
