function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
// const ROOT_KYC = '/kyc';
const ROOT_BANK = '/bank';
const ROOT_CKYC = '/ckyc';
const ROOT_KYC = '/kyc-v2';

// Ewi
export const EWI_ONBOARD = '/ewi/dashboard';
export const EWI_HOMEPAGE = '/ewi/home-page';
export const PENDING_AMOUNT = '/ewi/pending-amount';
export const LOAN_UTILISED = '/ewi/loan-utilised';
export const ROOT_EWI = '/ewi';
export const LOAN_OFFER_ID = '/ewi/offer-Id';
export const DUE_AMOUNT = '/ewi/due-amount';
export const DUE_REPAYMENT = '/ewi/due-payment';
export const FULL_REPAYMENT = '/ewi/due-payment';
export const EARLY_REPAYMENT = '/ewi/early-repayment';

export const USAGE_REPAYMENT = '/usage-repayment';
export const LANGUAGE_SELECT = '/language-select';
export const ROOT_AMOUNT_REPAYMENT = '/repayment';
export const LOADING = '/loading';

export const ONBOARD_SETUP = '/onboard-setup';
export const AUTO_PAYMENT = '/auto-payment';

export const PATH_AUTOPAY = {
  root: AUTO_PAYMENT,
  processing: path(AUTO_PAYMENT, '/processing'),
  status: path(AUTO_PAYMENT, '/status')
};

export const PATH_CKYC = {
  root: ROOT_CKYC,
  processing: path(ROOT_CKYC, '/processing'),
  postalCodeVerify: path(ROOT_CKYC, '/postal-code-verify'),
  success: path(ROOT_CKYC, '/success'),
  fail: path(ROOT_CKYC, '/fail')
};

export const PATH_EWI = {
  root: ROOT_EWI,
  repayment: path(ROOT_EWI, '/repayment'),
  loanOfferViewDetails: path(ROOT_EWI, '/loan-offer/view'),
  loanAmount: path(ROOT_EWI, '/loan-amount'),
  loanConfirmation: path(ROOT_EWI, '/loan-confirmation'),
  instalmentDetails: path(ROOT_EWI, '/instalment-details'),
  plandetails: path(ROOT_EWI, '/plan-details'),
  history: path(ROOT_EWI, '/history'),
  support: path(ROOT_EWI, '/support'),
  ewi_language_preferred: path(ROOT_EWI, '/language-preferred'),
  not_eligible_kyc_user: path(ROOT_EWI, '/not-eligible')
};

export const PATH_EWI_REPAYMENT = {
  root: ROOT_AMOUNT_REPAYMENT,
  dueRepayment: path(ROOT_EWI, '/due-payment'),
  fullRepayment: path(ROOT_EWI, '/full-payment')
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  mobileNumberVerification: (ROOTS_AUTH, '/number-update'),
  signUp: path(ROOTS_AUTH, '/sign-up'),
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  nonEligible: '/non-eligible'
};

export const PATH_LOC_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: path(ROOTS_DASHBOARD, '/home'),
  creditOffer: path(ROOTS_DASHBOARD, '/credit-offer'),
  support: path(ROOTS_DASHBOARD, '/support'),
  creditDetail: path(ROOTS_DASHBOARD, '/credit-offer/view'),
  history: path(ROOTS_DASHBOARD, '/history'),
  planDetails: path(ROOTS_DASHBOARD, '/my-plans'),
  finishUp: path(ROOTS_DASHBOARD, '/finish-up'),
  language_preferred: path(ROOTS_DASHBOARD, '/language-preferred')
};

export const PATH_KYC = {
  root: ROOT_KYC,
  selfie: path(ROOT_KYC, '/selfie'),
  liveSelfie: path(ROOT_KYC, '/live-selfie'),
  addressDashboard: path(ROOT_KYC, '/address'),
  adress_digilocker: path(ROOT_KYC, '/address-digilocker'),
  address_verify: path(ROOT_KYC, '/address-verify'),
  address_proof_doc: path(ROOT_KYC, '/address-proof'),
  address_photo: path(ROOT_KYC, '/address-photo'),
  digilocker: path(ROOT_KYC, '/address-digilocker'),
  digilockerSuccess: path(ROOT_KYC, '/address-digilocker/success'),
  addressDetail: path(ROOT_KYC, '/address-details'),
  digilocAddressDetail: path(ROOT_KYC, '/digi-address-details'),
  pancard: path(ROOT_KYC, '/pancard'),
  user_tutorial: path(ROOT_KYC, '/user-tutorial'),
  pancard_details: path(ROOT_KYC, '/pancard-details'),
  pan_doc: path(ROOT_KYC, '/pan-doc'),
  pan_doc_success: path(ROOT_KYC, '/pan-doc/success'),
  not_eligible_kyc_user: path(ROOT_KYC, '/not-eligible')
};

export const PATH_READY_TO_SPEND = '/lets-go';
export const FIX_NOW = '/fix-now';
export const PATH_BANK = {
  root: ROOT_BANK,
  register: path(ROOT_BANK, '/bank-register'),
  pennyVerify: path(ROOT_BANK, '/penny-verification'),
  pennyConfirm: path(ROOT_BANK, '/penny-confirm'),
  tranProcess: path(ROOT_BANK, '/transaction-processing'),
  bankTransfer: path(ROOT_BANK, '/transfer'),
  transactionDetail: path(ROOT_BANK, '/transaction-detail')
};

export const PATH_REPAYMENT = {
  root: ROOT_AMOUNT_REPAYMENT,
  processing: path(ROOT_AMOUNT_REPAYMENT, '/processing'),
  redirect: path(ROOT_AMOUNT_REPAYMENT, '/redirect')
};
