import { createSlice } from '@reduxjs/toolkit';
import {
  creditUnlockScreenDetailsApi,
  fetchAvailablePlansApi,
  fetchPlanDetailsApi,
  getUsageSlabFeeApi
} from './plansApi';

const initialState = {
  // businessSelected: {},
  availablePlanList: [],
  isPlanPageLoading: false,
  isPlanDetailFetching: false,
  selPlanDetails: {},
  creditPlanDetails: {},
  usageSlabDetails: {}
};

const slice = createSlice({
  name: 'plans',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAvailablePlansApi.pending]: (state) => {
      state.isPlanPageLoading = true;
    },
    [fetchAvailablePlansApi.fulfilled]: (state, action) => {
      state.availablePlanList = action.payload;
      state.isPlanPageLoading = false;
    },
    [fetchAvailablePlansApi.rejected]: (state) => {
      state.isPlanPageLoading = false;
    },
    [fetchPlanDetailsApi.pending]: (state) => {
      state.selPlanDetails = {};
      state.isPlanDetailFetching = true;
    },
    [fetchPlanDetailsApi.fulfilled]: (state, action) => {
      state.selPlanDetails = action.payload;
      state.isPlanDetailFetching = false;
    },
    [creditUnlockScreenDetailsApi.fulfilled]: (state, action) => {
      state.creditPlanDetails = action.payload;
      state.isPlanDetailFetching = false;
    },
    [fetchPlanDetailsApi.rejected]: (state) => {
      state.selPlanDetails = {};
      state.isPlanDetailFetching = false;
    },
    [getUsageSlabFeeApi.fulfilled]: (state, action) => {
      state.usageSlabDetails = action.payload;
    }
  }
});

export default slice.reducer;
