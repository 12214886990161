import axios from 'axios';
import axiosRetry from 'axios-retry';
import _ from 'lodash';
// import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import { localStorageKey } from 'utils/constants/common';
import { BASE_URL } from '../config';
import { store } from '../redux/store';
import { commonActions } from '../redux/common';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 60000
});
// axiosRetry(axiosInstance, {
//   retries: 3, // number of retries
//   retryDelay: (retryCount) => {
//     console.log(`retry attempt: ${retryCount}`);
//     return retryCount * 2000; // time interval between retries
//   },
//   retryCondition: (error) =>
//     // if retry condition is not specified, by default idempotent requests are retried
//     error.response.status === 502 ||
//     error.response.status === 501 ||
//     error.response.status === 500 ||
//     error.response.status === 503
// });

export default axiosInstance;
