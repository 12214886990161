export const bannerActionConst = {
  NO_ACTION: 'NO_ACTION',
  DUE_REPAYMENT: 'DUE_REPAYMENT',
  INSTALMENT_SCHEDULE: 'INSTALMENT_SCHEDULE'
};

export const sharedSectionStatusConst = {
  SECTION_HIDE: 'SECTION_HIDE',
  SECTION_LOCK: 'SECTION_LOCK',
  SECTION_ENABLE: 'SECTION_ENABLE'
};

export const EWIstatusConst = {
  EINotUsed: 'EINotUsed',
  EIPartiallyUsed: 'EIPartiallyUsed',
  EIUsedAll: 'EIUsedAll',
  EIStarted: 'EIStarted'
};

export const instalmentPaidStatusConst = {
  INSTALMENT_YET_TO_BE_PAID: 'INSTALMENT_YET_TO_BE_PAID',
  INSTALMENT_NOT_PAID: 'INSTALMENT_NOT_PAID',
  INSTALMENT_PARTIALLY_PAID: 'INSTALMENT_PARTIALLY_PAID',
  INSTALMENT_PAID: 'INSTALMENT_PAID'
};

export const instalmentScheduleStatusConst = {
  PAID: 'PAID',
  LOW_PAYMENT: 'LOW_PAYMENT',
  WAITING_FOR_CONFIRMED_DEDUCTION: 'WAITING_FOR_CONFIRMED_DEDUCTION',
  YET_TO_BE_PAID: 'YET_TO_BE_PAID'
};

export const bannerStatusConst = {
  NO_ACTION: 'NO_ACTION',
  DUE_REPAYMENT: 'DUE_REPAYMENT',
  INSTALMENT_SCHEDULE: 'INSTALMENT_SCHEDULE'
};

export const repayType = {
  due_payment: 'due',
  full_payment: 'full',
  instalment_payment: 'instalment'
};
