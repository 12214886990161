import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactGA from 'react-ga4';
// utils
// import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import { localStorageKey } from 'utils/constants/common';
import { PATH_AUTH } from 'routes/paths';
import axios from '../utils/axios';
import { setSession } from '../utils/jwt';
import { USER_ROLE } from '../config';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  signupData: null,
  userActivity: null,
  loginReq: {}
};
// uncomment the below line for stage release
const baseURL = window.location.origin;

// uncomment the below line for local developement
// const baseURL = 'http://localhost:56576';
// const baseURL = 'http://192.168.29.72:56576';

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  TOGGLE_INITIALIZE: (state, action) => ({
    ...state,
    isInitialized: action.payload
  }),
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      signupData: null
    };
  },
  SENDOTP: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user
    };
  },
  VERIFYOTP: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user
    };
  },
  LOGIN_REQ: (state, action) => ({ ...state, loginReq: action.payload })
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  // getPartner: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  sendOtp: () => Promise.resolve(),
  verifyOtp: () => Promise.resolve(),
  getUser: () => Promise.resolve(),
  setBackLoading: () => Promise.resolve(),
  initPartnerUser: () => Promise.resolve(),
  getQuessUserDetails: () => Promise.resolve(),
  hVLogin: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const accessToken = window?.localStorage?.getItem(localStorageKey.accessToken);

  useEffect(() => {
    const initialize = async () => {
      try {
        const userId = window?.localStorage?.getItem(localStorageKey.user_id);
        if (accessToken) {
          setSession(accessToken);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: userId
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, [accessToken]);

  // useEffect(() => {
  //   if (_.isEmpty(accessToken)) {
  //     dispatch({
  //       type: 'INITIALIZE',
  //       payload: {
  //         isAuthenticated: false,
  //         user: null
  //       }
  //     });
  //   }
  // }, [accessToken]);

  const login = async (params) => {
    const response = await axios.post(`${baseURL}/v2/login`, params);
    if (response?.data?.token) {
      const user = response.data;
      if (user) {
        setSession(user.token);
        // if (window?.gtag)
        //   window?.gtag('set', 'user_properties', {
        //     user_id: user.user_id,
        //     partner_id: user?.user_info?.partner_id
        //   });
        ReactGA.gtag('set', 'user_properties', {
          user_id: user.user_id,
          partner_id: user?.user_info?.partner_id
        });

        localStorage.setItem(localStorageKey.user_id, user.user_id);
        dispatch({
          type: 'LOGIN',
          payload: {
            user: user.user_id
          }
        });
      }
      return user;
    }
  };

  // const getPartner = async (distId) => {
  //   const response = await axios.get(`${baseURL}/distribution/${distId}`);
  //   return response.data;
  // };

  const hVLogin = async () => {
    const res = await axios.get(`${baseURL}/hv-login`);
    return res?.data?.result?.token;
  };

  const sendOtp = async (params) => {
    dispatch({
      type: 'LOGIN_REQ',
      payload: params
    });
    const resp = await axios.post(`${baseURL}/v2/requestOTP`, { phone: params.phone });
    return resp.data;
  };

  const initPartnerUser = async ({ detail, distribution }) => {
    try {
      const response = await axios.get(`${baseURL}/partner/user`, { params: { detail, distribution } });
      return response.data;
    } catch (err) {
      console.log('err is', err);
    }
  };

  const getQuessUserDetails = async ({ employee_id }) => {
    const response = await axios.get(`${baseURL}/quess/user-details`, { params: { employee_id } });
    return response.data;
  };

  const register = async ({ email, password, firstName, lastName, phoneNumber, role }) => {
    const response = await axios.post('signup', {
      email,
      password,
      firstName,
      lastName,
      phoneNumber,
      role: role || USER_ROLE
    });
    if (response?.data?.status === 'success') {
      const { data } = response.data;
      const user = { ...data };
      delete user.loginToken;
      user.displayName = `${user.firstName} ${user.lastName}`;
      setSession(data.loginToken);
      dispatch({
        type: 'REGISTER',
        payload: {
          user
        }
      });
    }
  };

  const logout = async () => {
    setSession(null);
    const i18nextLng = localStorage.getItem(localStorageKey.i18nextLng);
    const isLangSelected = localStorage.getItem(localStorageKey.isLangSelected);
    localStorage.clear();
    localStorage.setItem(localStorageKey.i18nextLng, i18nextLng);
    localStorage.setItem(localStorageKey.isLangSelected, isLangSelected);

    // localStorage.removeItem('accessToken');
    // if (!_.isEmpty(i18nextLng)) {
    //   localStorage.setItem('isLangSelected', true);
    // }
    // serviceWorkerRegistration.unregister();
    dispatch({ type: 'LOGOUT' });
    window.location.replace(`${PATH_AUTH.login}${window?.location?.search}`);
  };

  const verifyOtp = async ({ code, phoneNumber }) =>
    axios.post('verifyOtp', {
      code,
      phoneNumber
    });
  const resetPassword = () => {};

  const updateProfile = () => {};

  const updateSignupData = (signupData) => {
    dispatch({
      type: 'SIGNUP_DATA',
      payload: {
        signupData
      }
    });
  };

  const updateUserActivity = (userActivity) => {
    dispatch({
      type: 'USER_ACTIVITY',
      payload: {
        userActivity
      }
    });
  };
  const getUser = async ({ userId, phoneNumber, role }) =>
    axios.post('getUser', {
      phoneNumber,
      role: role || 'oem',
      userId
    });

  const markUserVerified = async (userData) => {
    const response = await axios.post('markVerified', {
      userId: userData._id
    });
    if (response?.data?.status === 'success') {
      dispatch({
        type: 'MARK_VERIFIED'
      });
    }
  };

  const setBackLoading = (isInitialized) => {
    dispatch({
      type: 'TOGGLE_INITIALIZE',
      payload: isInitialized
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        // getPartner,
        logout,
        register,
        resetPassword,
        updateProfile,
        updateSignupData,
        updateUserActivity,
        sendOtp,
        verifyOtp,
        getUser,
        markUserVerified,
        setBackLoading,
        initPartnerUser,
        getQuessUserDetails,
        hVLogin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
