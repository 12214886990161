import axios from 'utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { PRODUCT_TYPE } from 'utils/constants/featuresConst';

// Mock Responses
// import creditUnlockScreenDetailsRes from "./mockResponses/creditUnlockScreenDetails.json"

const urls = {
  fetchAvailablePlans: 'credit/plans/FetchAvailableplans',
  fetchAvailableDetails: 'credit/plans/FetchPlanDetails',
  getUsageSlabFee: 'credit/plans/GetUsageSlabFee',
  creditUnlockScreenDetails: 'credit/plan/CreditUnlockScreenDetails'
};

export const fetchAvailablePlansApi = createAsyncThunk('karma/fetchAvailablePlans', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.fetchAvailablePlans, params);
    if (response.data) {
      const resData = response.data;
      if (resData?.items) {
        resData.subscribedPlan = [];
        resData.offeredCreditPlan = [];
        _.forEach(resData?.items, (plan) => {
          if (plan.product_type === PRODUCT_TYPE.CREDIT) {
            if (plan?.is_subscribed) {
              resData.subscribedPlan.push(plan);
            } else {
              resData.offeredCreditPlan.push(plan);
            }
          }
        });
      }
      resData.isSubscribedToAnyPlan = !_.isEmpty(resData.subscribedPlan);
      resData.isPlanAvailable = !_.isEmpty(resData?.items);

      return resData;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const fetchPlanDetailsApi = createAsyncThunk('karma/fetchPlanDetails', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.fetchAvailableDetails, params);

    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const creditUnlockScreenDetailsApi = createAsyncThunk(
  'karma/creditUnlockScreenDetailsApi',
  async (params, thunkApi) => {
    try {
      // console.log({creditUnlockScreenDetailsRes})
      // return creditUnlockScreenDetailsRes.successScenario;
      const response = await axios.post(urls.creditUnlockScreenDetails, params);

      if (response.data) {
        return response.data;
      }
      return thunkApi.rejectWithValue('Error! Try again later');
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getUsageSlabFeeApi = createAsyncThunk('karma/getUsageSlabFee', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.getUsageSlabFee, params);

    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});
