import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Page from 'components/Page';
import _ from 'lodash';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import videoConst from 'utils/constants/videoConst';
import useLocales from 'hooks/useLocales';
import useCommon from 'hooks/useComon';
import { kycActions } from 'redux/kyc';
import PanCardInput from './PanCardInput';
import PanBannerImg from '../component/PanBannerImg';

const RootStyle = styled(Page)(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  // height: '100%',
  paddingBottom: '32px'
}));

const regpan = /^([a-zA-Z]([a-zA-Z]([a-zA-Z]([a-zA-Z]([a-zA-Z]([0-9]([0-9]([0-9]([0-9]([a-zA-Z])?)?)?)?)?)?)?)?)?)?$/;

const panSchema = Yup.object().shape({
  panNo: Yup.string().matches(regpan)
});

function PanCardV2() {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pushGaEvent } = useCommon();

  const { userKycDetails = {}, panDetails = '' } = useSelector(({ kycReducer }) => kycReducer);
  const [err, setErr] = useState('');
  const [showPanVerify, setPanVerify] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(kycActions.setPanDetails(''));
  }, []);

  const handleChange = (event) => {
    const str = event.target.value;
    setErr(null);
    if (!str) {
      dispatch(kycActions.setPanDetails(str.toUpperCase()));
    } else {
      panSchema
        .validate({ panNo: str?.toUpperCase() })
        .then(() => {
          dispatch(kycActions.setPanDetails(str.toUpperCase()));
        })
        .catch((err) => {
          console.log('err is', err);
        });
    }
  };

  return (
    <RootStyle title=" Pancard Verify | Karmalife">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          py: 3,
          my: 1
        }}
      >
        {!showPanVerify && (
          <>
            <PanCardInput value={panDetails} handleChange={handleChange} err={err} />
            <PanBannerImg />
          </>
        )}
      </Box>
    </RootStyle>
  );
}

export default PanCardV2;
