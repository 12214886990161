import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Stack } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import useLocales from 'hooks/useLocales';

InfoHeader.propTypes = {
  heading: PropTypes.string,
  headingSx: PropTypes.object,
  containerSx: PropTypes.object,
  activityStatus: PropTypes.string,
  subHeading: PropTypes.string
};
function InfoHeader(props) {
  const { translate } = useLocales();
  return (
    <Box component="div" sx={{ ...props.containerSx }}>
      {(props.heading || props.activityStatus) && (
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
          {props.heading && (
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '16px',
                color: 'black',
                letterSpacing: '0.4px',
                ...props.headingSx
              }}
            >
              {props.heading}
            </Typography>
          )}
          {props?.activityStatus === 'DONE' && (
            <Box component="div">
              <Typography
                color="primary"
                sx={{ display: 'flex', alignItems: 'center', fontSize: '10px', fontWeight: 900 }}
              >
                <CheckCircleIcon sx={{ fontSize: '13px', marginRight: '4px' }} />
                {translate('common_done_label')}
              </Typography>
            </Box>
          )}

          {props?.activityStatus === 'PENDING' && (
            <Box component="div">
              <Typography
                sx={{ display: 'flex', alignItems: 'center', fontSize: '10px', fontWeight: 900, color: '#E01E5A' }}
              >
                <ErrorIcon sx={{ fontSize: '13px', marginRight: '4px' }} />
                {translate('common_pending')}
              </Typography>
            </Box>
          )}
        </Stack>
      )}

      {props.subHeading && (
        <Typography
          sx={{ fontSize: '12px', color: '#6F6F6F', letterSpacing: '0.25px', lineHeight: '16px', fontWeight: 500 }}
        >
          {props.subHeading}
        </Typography>
      )}
    </Box>
  );
}

export default InfoHeader;
