import { createSlice } from '@reduxjs/toolkit';
import { fetchPlanDetailsApi } from '../plans/plansApi';

const initialState = {
  // businessSelected: {},
  loanDetails: {}
};

const slice = createSlice({
  name: 'loanDetails',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPlanDetailsApi.fulfilled]: (state, action) => {
      state.loanDetails = action.payload;
    }
  }
});
export default slice.reducer;
