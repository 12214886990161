import { createSlice } from '@reduxjs/toolkit';
import { getUserKycStatus, verifyUserPanNumber, fetchDigilockerApi, checkCKycStatusApi } from './kycApi';

const initialState = {
  userKycDetails: [],
  panOwnerName: '',
  userAadharDetails: {},
  ckycStatusDetails: {},
  ckycError: {},
  panDetails: '',
  ckycRequestBody: {},
  isCkycCheckLoading: false,
  isKycDetailsFetching: false,
  panFormDetails: { isEnabled: false, data: {} },
  backNavErrCardDetails: null
};

const slice = createSlice({
  name: 'kycSlice',
  initialState,
  reducers: {
    setCkycRequestBody: (state, action) => {
      state.ckycRequestBody = action.payload;
    },
    setPanDetails: (state, action) => {
      state.panDetails = action.payload;
    },
    setEnableButton: (state, action) => {
      state.panFormDetails = action.payload;
    },
    setBackNavErrDetails: (state, action) => {
      state.backNavErrCardDetails = action.payload;
    }
  },
  extraReducers: {
    [getUserKycStatus.fulfilled]: (state, action) => {
      state.userKycDetails = action.payload;
      state.isKycDetailsFetching = false;
    },
    [getUserKycStatus.pending]: (state) => {
      state.userKycDetails = {};
      state.isKycDetailsFetching = true;
    },
    [getUserKycStatus.pending]: (state) => {
      state.isKycDetailsFetching = false;
    },
    [verifyUserPanNumber.fulfilled]: (state, action) => {
      state.panOwnerName = action.payload;
    },
    [fetchDigilockerApi.pending]: (state) => {
      state.userAadharDetails = {};
    },
    [fetchDigilockerApi.fulfilled]: (state, action) => {
      state.userAadharDetails = action?.payload?.data || {};
    },
    [fetchDigilockerApi.rejected]: (state) => {
      state.userAadharDetails = {};
    },
    [checkCKycStatusApi.pending]: (state, action) => {
      state.ckycStatusDetails = {};
      state.ckycError = {};
      state.isCkycCheckLoading = true;
    },
    [checkCKycStatusApi.fulfilled]: (state, action) => {
      state.ckycStatusDetails = action.payload;
      state.ckycError = {};
      state.isCkycCheckLoading = false;
    },

    [checkCKycStatusApi.rejected]: (state, action) => {
      state.ckycStatusDetails = {};
      state.ckycError = action.payload;
      state.isCkycCheckLoading = false;
    }
  }
});
export const kycActions = slice.actions;
export default slice.reducer;
