import axios from 'utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

const urls = {
  getOfferIntroDetails: '/common/onboard/GetOfferIntroDetails',
  deleteBankAcc: '/common/user/RemoveBankAccount'
};

export const getOfferIntroDetailsApi = createAsyncThunk('karma/getOfferIntroDetails', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.getOfferIntroDetails, params);

    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const delelteBankAccountApi = createAsyncThunk('karma/delelteBankAccountApi', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.deleteBankAcc, params);
    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});
