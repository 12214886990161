const videoConst = {
  kyc_video_id: '1ksHhNMbcdQ?start=29',
  selfie_video_id: 'e83AZh10eTg',
  pan_video_id: 'cIA0WRkPIjs',
  pan_doc_upload_id: 'g9ZHC4JlJP4?start=2',
  ekyc_video_id: 'u2ZuiXuoWXs?start=64',
  okyc_video_id: 'auRoNgK8SHg',
  digilocker_video_id: 'Uu0Vj2bffUY',
  photo_upload_id: 'EP-9P1BXzMk',
  ckyc_video_id: 'BvZD2osiR-4',
  auto_pay_id: 'pIiusYShL5w'
};

export default videoConst;
