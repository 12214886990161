import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import { Suspense, lazy } from 'react';

// layouts
import { appendSearchParams } from 'utils/common';
import KycLayoutV2 from 'layouts-v2/kyc-layout-v2';
import PanCardV2 from 'pages/kyc-v2/pan-card/PanCard';
import CKYCDashboardV2 from 'pages/ckyc-v2';
import CkycSuccessDetailsV2 from 'pages/ckyc-v2/CkycSuccessDetailsV2';
import KycPanLayoutV2 from 'layouts-v2/kyc-pan-layout-v2';
import FixNowLayout from 'layouts/FixNow-layout';
import EwiLayout from '../layouts-v2/ewi-layout';
import FixNowComponent from '../pages/kyc-v2/fix-now';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import KycLayout from '../layouts/kyc-layout';
import BankLayout from '../layouts/bank-layout';
import SignUpLayout from '../layouts/signup-layout';
import CKYCLayout from '../layouts/ckyc-layout';
import CreditOfferLayout from '../layouts/credit-layout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

import LoadingScreen from '../components/LoadingScreen';
import RepayLayout from '../layouts/repay-layout';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname, search } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'verify',
          element: (
            <GuestGuard>
              <VerifyOtp />
            </GuestGuard>
          )
        },
        {
          path: 'sign-up',
          element: (
            <AuthGuard>
              <SignUpLayout />
            </AuthGuard>
          ),
          children: [{ path: '', element: <SignUpPage /> }]
        }
      ]
    },
    {
      path: '/onboard-setup',
      element: (
        <AuthGuard>
          <OnboardSetupPage />
        </AuthGuard>
      )
    },
    {
      path: '/non-eligible',
      element: (
        <AuthGuard>
          <NonEligibleUser />
        </AuthGuard>
      )
    },
    {
      path: 'repayment',
      element: (
        <AuthGuard>
          <RepayLayout />
        </AuthGuard>
      ),
      children: [{ path: '', element: <RepaymentPage /> }]
    },
    // this is working
    {
      path: 'ewi',
      element: (
        <AuthGuard>
          <EwiDashLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <EwiHomePage /> },
        { path: 'due-repayment', element: <EwiHomePage /> },
        { path: 'pending-amount', element: <PendingAmount /> },
        { path: 'history', element: <History /> },
        { path: 'support', element: <EwiSupportPage /> },
        { path: 'not-eligible', element: <NotEligibleKYCUser /> }
      ]
    },
    {
      path: 'ewi',
      element: (
        <AuthGuard>
          <EwiLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'loan-offer/view/:offerId', element: <LoanOfferDetailsPage /> },
        { path: 'loan-offer/verify-employee/:offerId', element: <OfferEmployeeId /> },
        { path: 'loan-amount', element: <LoanAmountPage /> },
        { path: 'loan-confirmation', element: <LoanAmountConfirmation /> },
        { path: 'due-amount', element: <DueAmount /> },
        { path: 'early-repayment', element: <EarlyRepayment /> },
        { path: 'plan-details', element: <EWIPlanDetailsPage /> },
        { path: 'not-eligible', element: <NotEligibleKYCUser /> }
      ]
    },
    {
      path: 'ewi/instalment-details',
      element: (
        <AuthGuard>
          <InstallmentSchedulePage />
        </AuthGuard>
      )
    },

    {
      path: '/ckyc',
      element: (
        <AuthGuard>
          <CKYCLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <CKYCDashboard /> },
        { path: 'processing', element: <CkycProcessingPage /> },
        { path: 'postal-code-verify', element: <PostalCodeVerify /> },
        { path: 'success', element: <CkycSuccessDetails /> },
        { path: 'fail', element: <CkycFailPage /> }
      ]
    },
    {
      path: '/ckyc-v2',
      element: (
        <AuthGuard>
          <CKYCLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <CKYCDashboardV2 /> },
        { path: 'success', element: <CkycSuccessDetailsV2 /> }
      ]
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'home', element: <Dashboard /> },
        { path: 'credit-offer', element: <CreditOfferPage /> },
        { path: 'support', element: <EwiSupportPage /> },
        { path: 'history', element: <History /> }
      ]
    },
    {
      path: 'ewi/language-preferred',
      element: (
        <AuthGuard>
          <CreditOfferLayout />
        </AuthGuard>
      ),
      children: [{ path: '', element: <LanguagePreferred /> }]
    },
    {
      path: 'dashboard/language-preferred',
      element: (
        <AuthGuard>
          <CreditOfferLayout />
        </AuthGuard>
      ),
      children: [{ path: '', element: <LanguagePreferred /> }]
    },

    {
      path: 'dashboard/credit-offer/view/:offerId',
      element: (
        <AuthGuard>
          <CreditOfferLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <CreditOfferDetails /> }
        // { path: 'employee-credit', element: <CreditEmployePage /> }
      ]
    },
    {
      path: 'dashboard/my-plans/:planId',
      element: (
        <AuthGuard>
          <PlanDetailsPage />
        </AuthGuard>
      )
    },
    {
      path: 'bank/transaction-detail',
      element: (
        <AuthGuard>
          <TransactionDetails />
        </AuthGuard>
      )
    },
    {
      path: 'bank',
      element: (
        <AuthGuard>
          <BankLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'bank-register', element: <BankAccRegister /> },
        {
          path: 'penny-verification',
          element: <PennyConfirmation />
        },
        {
          path: 'transfer',
          element: <BankTransaction />
        }
      ]
    },
    {
      path: 'ewi',
      element: (
        <AuthGuard>
          <RepayLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'repayment', element: <EWIRepaymentPage /> },
        { path: 'due-payment', element: <DueRepayment /> },
        { path: 'full-payment', element: <FullRepayment /> }
      ]
    },
    // { path: 'ewi/full-payment', element: <FullRepayment /> },
    {
      path: 'repayment/processing',

      element: <RepaymentProcessing />
    },
    { path: 'repayment/redirect', element: <RepaymentRedirect /> },
    {
      path: 'bank/transaction-processing',
      element: <TransactionProcessing />
    },
    {
      path: 'dashboard/finish-up',
      element: <FinishUp />
    },

    {
      path: 'kyc',
      element: (
        <AuthGuard>
          <KycLayout />
        </AuthGuard>
      ),
      children: [
        // Main Kyc dashboard Route
        { path: '', element: <Kyc /> },
        //  Selfie dashboard route
        { path: 'selfie', element: <SelfieDashboard /> },
        { path: 'live-selfie', element: <LiveSelfieDashboard /> },
        {
          path: 'address',
          // Aadhar card dashboard route
          element: <AadharDashboard />
          // element: <AddressVerfy />,
        },

        {
          path: 'address-proof',
          element: <AddressDocumentsProof />
        },
        {
          path: 'address-details',
          element: <AddressDetails />
        },
        {
          path: 'address-photo',
          element: <AddressPhoto />
        },
        {
          path: 'pancard',
          element: <PanCard />
        }
      ]
    },
    {
      path: 'fix-now',
      element: (
        <AuthGuard>
          <FixNowLayout />
        </AuthGuard>
      ),
      children: [{ path: '', element: <FixNowComponent /> }]
    },
    {
      path: 'kyc-v2',
      element: (
        <AuthGuard>
          <KycLayoutV2 />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <KycV2 /> },
        {
          path: 'address',
          element: <AadharDashboardV2 />
        },
        {
          path: 'address-proof',
          element: <AddressDocumentsProofV2 />
        },
        {
          path: 'address-details',
          element: <AddressDetailsV2 />
        },
        {
          path: 'address-photo',
          element: <AddressPhotoV2 />
        },
        {
          path: 'pan-doc',
          element: <PanDocUpload />
        },
        { path: 'pan-doc/success', element: <PanPreUploadPage /> },
        { path: 'selfie', element: <SelfieDashboard /> },
        { path: 'live-selfie', element: <LiveSelfieDashboard /> }
      ]
    },
    {
      path: 'kyc-v2/digi-address-details',
      element: (
        <AuthGuard>
          <KycLayoutV2 />
        </AuthGuard>
      ),
      children: [{ path: '', element: <DigilocAddressDetails /> }]
    },
    {
      path: 'kyc-v2',
      element: (
        <AuthGuard>
          <KycPanLayoutV2 />
        </AuthGuard>
      ),
      children: [
        { path: 'pancard', element: <PanCardV2 /> },
        { path: 'pancard-details', element: <PanDetails /> }
      ]
    },
    {
      path: 'kyc-v2',
      element: (
        <AuthGuard>
          <NotEligibleKYCUserLayout />
        </AuthGuard>
      ),
      children: [{ path: 'not-eligible', element: <NotEligibleKYCUser /> }]
    },
    {
      path: 'kyc-v2/user-tutorial',
      element: (
        <AuthGuard>
          <UserTutorial />
        </AuthGuard>
      )
    },
    {
      path: 'auto-payment',
      element: (
        <AuthGuard>
          <WelcomeScreen />
        </AuthGuard>
      )
    },
    {
      path: 'auto-payment/processing',
      element: (
        <AuthGuard>
          <ProcessingScreen />
        </AuthGuard>
      )
    },
    {
      path: 'auto-payment/status',
      element: (
        <AuthGuard>
          <StatusScreen />
        </AuthGuard>
      )
    },
    {
      path: 'usage-repayment',
      element: (
        <AuthGuard>
          <CreditOfferLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <PartnerUsageRepayment />
        }
      ]
    },
    { path: 'kyc-v2/address-digilocker', element: <Digilocker /> },

    { path: 'kyc-v2/address-digilocker/success', element: <DigilockerSucess /> },

    { path: 'language-select', element: <InitLanguages /> },

    // ready to spend now page
    {
      path: 'bank/penny-confirm',
      element: <PennyDropConfirm />
    },

    {
      path: 'lets-go',
      element: (
        <AuthGuard>
          <ReadyToSpend />
        </AuthGuard>
      )
    },

    {
      path: '/loading',
      element: (
        <AuthGuard>
          <HomeLoader />
        </AuthGuard>
      )
    },
    {
      path: '/',
      element: <Navigate to={appendSearchParams('/loading')} replace />
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: 'number-update',
      element: <MobileVerification />
    }
  ]);
}

// IMPORT COMPONENTS
// Authentication

const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const VerifyOtp = Loadable(lazy(() => import('../pages/authentication/VerifyOtp')));
const SignUpPage = Loadable(lazy(() => import('../pages/authentication/SignUp')));
const FinishUp = Loadable(lazy(() => import('pages/home/finish-up')));
const InitLanguages = Loadable(lazy(() => import('../pages/authentication/InitLanguages')));
// compnents
const ReadyToSpend = Loadable(lazy(() => import('../components/ReadyToSpend')));

// bank-transfer
const BankAccRegister = Loadable(lazy(() => import('../pages/bank-transfer/BankAccRegister')));
const TransactionDetails = Loadable(lazy(() => import('../pages/bank-transfer/TransactionDetails')));

const CreditOfferPage = Loadable(lazy(() => import('../pages/home/credit-offer/CreditOfferPage')));
const CreditOfferDetails = Loadable(lazy(() => import('../pages/home/credit-offer/CreditDetailsPage')));
const CreditEmployePage = Loadable(lazy(() => import('../pages/home/credit-offer/CreditEmployePage')));

// UsageRepayment
const PartnerUsageRepayment = Loadable(lazy(() => import('../pages/usage-repayment/PartnerUsageRepayment')));

// EwiLayout
const EWIRepaymentPage = Loadable(lazy(() => import('../pages/ewi/EWIRepaymentPage')));
const EwiDashLayout = Loadable(lazy(() => import('../layouts-v2/ewi-dashboard-layout')));
const OfferEmployeeId = Loadable(lazy(() => import('../pages/ewi/OfferEmployeeId')));
const EwiHomePage = Loadable(lazy(() => import('../pages/ewi/EwiHomePage')));
const InstallmentSchedulePage = Loadable(lazy(() => import('../pages/ewi/InstallmentSchedulePage')));
const PendingAmount = Loadable(lazy(() => import('../pages/ewi/PendingAmount')));
const LoanAmountConfirmation = Loadable(lazy(() => import('../pages/ewi/LoanAmountConfirmation')));
const DueAmount = Loadable(lazy(() => import('../pages/ewi/DueAmount')));
const EarlyRepayment = Loadable(lazy(() => import('../pages/ewi/EarlyRepayment')));
const EWIPlanDetailsPage = Loadable(lazy(() => import('../pages/ewi/PlanDetailsPage')));
const LoanAmountPage = Loadable(lazy(() => import('../pages/ewi/LoanAmountPage')));
const EwiSupportPage = Loadable(lazy(() => import('../pages/ewi/SupportPage')));
const LoanOfferDetailsPage = Loadable(lazy(() => import('../pages/home/loan-offer/LoanDetailsPage')));
const DueRepayment = Loadable(lazy(() => import('../pages/ewi/components/DueRepayment')));
const FullRepayment = Loadable(lazy(() => import('../pages/ewi/components/FullRepayment')));

// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/home')));
const Kyc = Loadable(lazy(() => import('../pages/kyc')));
const KycV2 = Loadable(lazy(() => import('../pages/kyc-v2')));
const Digilocker = Loadable(lazy(() => import('../pages/kyc-v2/aadhar/Digilocker')));
const DigilockerSucess = Loadable(lazy(() => import('../pages/kyc/aadhar/DigilockerSuccess')));
const AddressDetails = Loadable(lazy(() => import('../pages/kyc/aadhar/AddressDetails')));
const AadharDashboard = Loadable(lazy(() => import('../pages/kyc/aadhar')));
const AddressDocumentsProof = Loadable(lazy(() => import('../pages/kyc/aadhar/AddressDocumentsProof')));
const AddressPhoto = Loadable(lazy(() => import('../pages/kyc/aadhar/AddressPhotoUpload')));
const SelfieDashboard = Loadable(lazy(() => import('../pages/kyc-v2/selfie/SelfieDashboard')));
const LiveSelfieDashboard = Loadable(lazy(() => import('../pages/kyc-v2/selfie/LiveSelfieDashboard')));

const PanCard = Loadable(lazy(() => import('../pages/kyc/pan-card/PanCard')));
// const SupportPage = Loadable(lazy(() => import('../pages/home/support/SupportPage')));
const LanguagePreferred = Loadable(lazy(() => import('../pages/home/support/LanguagePreferred')));

const MobileVerification = Loadable(lazy(() => import('../pages/authentication/MobileVerification')));
const PlanDetailsPage = Loadable(lazy(() => import('../pages/home/plan-details')));

// Bank Transfer

const PennyDropConfirm = Loadable(lazy(() => import('../pages/bank-transfer/penny-flow/PennyDropConfirm')));
const TransactionProcessing = Loadable(lazy(() => import('../pages/bank-transfer/TransactionProcessing')));
const BankTransaction = Loadable(lazy(() => import('../pages/bank-transfer/BankTransaction')));
const History = Loadable(lazy(() => import('../pages/home/History')));
const PennyConfirmation = Loadable(lazy(() => import('../pages/bank-transfer/penny-flow/PennyConfirmation')));

// OnboardSetup
const OnboardSetupPage = Loadable(lazy(() => import('../pages/onboard-setup')));

// Home Loader
const HomeLoader = Loadable(lazy(() => import('../pages/home/HomeLoader')));
// Main Page
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NonEligibleUser = Loadable(lazy(() => import('../pages/NonEligibleUser')));

// CKYC
const CKYCDashboard = Loadable(lazy(() => import('../pages/ckyc')));
const CkycProcessingPage = Loadable(lazy(() => import('../pages/ckyc/CkycProcessingPage')));
const PostalCodeVerify = Loadable(lazy(() => import('../pages/ckyc/PostalCodeVerify')));
const CkycSuccessDetails = Loadable(lazy(() => import('../pages/ckyc/CkycSuccessDetails')));
const CkycFailPage = Loadable(lazy(() => import('../pages/ckyc/CkycFailPage')));

// REPAYMENT
const RepaymentPage = Loadable(lazy(() => import('../pages/repayment')));

const RepaymentProcessing = Loadable(lazy(() => import('../pages/repayment/component/RepaymentProcessing')));
const RepaymentRedirect = Loadable(lazy(() => import('../pages/repayment/component/RepaymentRedirect')));
// Kyc-v2
const NotEligibleKYCUser = Loadable(lazy(() => import('../pages/kyc-v2/component/NotEligible')));
const NotEligibleKYCUserLayout = Loadable(lazy(() => import('../layouts-v2/not-eligible')));
const PanDetails = Loadable(lazy(() => import('../pages/kyc-v2/pan-card/PanDetails')));
const AadharDashboardV2 = Loadable(lazy(() => import('../pages/kyc-v2/aadhar')));
const UserTutorial = Loadable(lazy(() => import('../pages/kyc-v2/UserTutorial')));
const AddressPhotoV2 = Loadable(lazy(() => import('../pages/kyc-v2/aadhar/AddressPhotoUpload')));
const AddressDetailsV2 = Loadable(lazy(() => import('../pages/kyc-v2/aadhar/AddressDetails')));
const DigilocAddressDetails = Loadable(lazy(() => import('../pages/kyc-v2/aadhar/DigilockerAddressDetail')));
const AddressDocumentsProofV2 = Loadable(lazy(() => import('../pages/kyc-v2/aadhar/AddressDocumentsProof')));
const PanDocUpload = Loadable(lazy(() => import('../pages/kyc-v2/pan-card/pan-doc/PanUpload')));
const PanPreUploadPage = Loadable(lazy(() => import('../pages/kyc-v2/pan-card/pan-doc/PanPreUpload')));

// Auto payment
const WelcomeScreen = Loadable(lazy(() => import('pages/auto-repayment/WelcomeScreen')));
const ProcessingScreen = Loadable(lazy(() => import('pages/auto-repayment/ProcessingScreen')));
const StatusScreen = Loadable(lazy(() => import('pages/auto-repayment/StatusScreen')));
