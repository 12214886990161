import React, { useState } from 'react';
import {} from '@mui/material';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button as ButtonUnstyled, buttonClasses } from '@mui/base/Button';
import { styled } from '@mui/system';
import SaveIcon from '@mui/icons-material/Save';
import { toUpper } from 'lodash';

const blue = {
  500: '#919191',
  600: '#0072E5',
  700: '#0059B2'
};

const CustomButtonRoot = styled(ButtonUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  background-color: ${blue[500]};
  padding: 12px 24px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;

  &.${buttonClasses.disabled} {
    opacity: 1;
    cursor: not-allowed;
  }
`;

function CustomButton(props) {
  return <CustomButtonRoot {...props} />;
}
Button.propTypes = {
  label: PropTypes.string,
  loadBt: PropTypes.bool,
  onButtonPress: PropTypes.func,
  disableBT: PropTypes.bool
};
export default function Button(props, { sx }) {
  const { label, loadBt, onButtonPress, disableBT, ...others } = props;
  return (
    <>
      {!disableBT ? (
        <LoadingButton
          loading={loadBt}
          // disabled
          fullWidth
          variant="contained"
          onClick={onButtonPress}
          sx={{
            fontSize: '16px',
            backgroundColor: '#2EB67D',
            color: 'white',
            border: '0px',
            height: '48px',
            textTransform: 'uppercase',
            boxShadow: '0px 2px 4px rgba( 0 0 0 / 35%)',
            ...sx
          }}
          {...others}
        >
          {toUpper(label)}
        </LoadingButton>
      ) : (
        <CustomButton
          sx={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            height: '48px',
            fontSize: '16px',
            textTransform: 'uppercase',
            color: 'white'
          }}
          disabled
        >
          {toUpper(label)}
        </CustomButton>
      )}
    </>
  );
}
