import { useEffect, useLayoutEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
// material
import { styled } from '@mui/material/styles';
//
import { PATH_EWI } from 'routes/paths';
import EwiNavbar from './EwiNavbar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundColor: 'white'
}));
const APP_BAR_MOBILE = 64;
const MainStyle = styled('div')(() => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingLeft: 24,
  paddingRight: 24,
  paddingTop: APP_BAR_MOBILE
}));

// ----------------------------------------------------------------------

export default function EwiLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { ewiDashboardDetails = {} } = useSelector(({ ewiReducer }) => ewiReducer);
  useEffect(() => {
    if (ewiDashboardDetails?.account_details?.eligibility_check_required) {
      navigate(PATH_EWI.not_eligible_kyc_user);
    }
  }, [location]);
  return (
    <>
      <RootStyle>
        <EwiNavbar />
        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
    </>
  );
}
