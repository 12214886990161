import axios from 'utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { createTransferParams } from 'utils/jwt';
import { localStorageKey } from 'utils/constants/common';
import { ONBOARD_ITEM_STATUS, onboardFailuresConst } from 'utils/constants/oboardConst';
import { mockApiResponse } from 'utils/mockResponse';

const urls = {
  getOnboardDashboard: 'common/onboard/GetOnboardDashboard',
  accountAndPreference: 'credit/user/GetAccountAndPreferences',
  listTransactions: 'credit/transaction/ListTransaction',
  transactionDetail: 'credit/transaction/GetTransaction',
  userNbfcTerms: 'common/settings/GetSettingsUserNbfcTerms',
  generateUserAccessToken: 'common/auth/GenerateUserAccessToken',
  termsAndCondition: 'common/misc/GetTermsAndCondition',
  acceptTermsAndCondition: 'common/misc/AcceptTermsAndCondition',
  getUsageAndRepayment: 'credit/misc/GetUsageAndRepaymentSummary',
  checkUserEligibility: 'credit/loan/CheckUserEligibility',
  GetLOCDashboardURL: 'credit/dashboard/GetLOCDashboard',
  consentSendOtp: '/common/otp/RequestOTP',
  bankAccountPennyDropVerification: '/common/user/BankAccountPennydropVerification'
};

// mock response
// import transactionDetailsRes from "./mockResponse/transactionDetails.json"

export const getOnboardDashboardApi = createAsyncThunk('karma/getOnboardDashboard', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.getOnboardDashboard, params);
    if (response.data) {
      const updatedResponse = { ...response.data };
      // Check if ckyc is enabled and one attempt is done
      if (updatedResponse?.is_ckyc_enabled && localStorage.getItem(localStorageKey.ckycAttempted) === 'true') {
        updatedResponse.is_ckyc_enabled = false;
      }
      if (!_.isEmpty(updatedResponse.onboard_verification_failures)) {
        updatedResponse.is_onboard_completed = false;
        const upOnboardItems = updatedResponse.onboard_items;

        _.forEach(updatedResponse.onboard_verification_failures, (failItem) => {
          switch (failItem.item) {
            case onboardFailuresConst.KYC_VERIFICATION: {
              const ind = _.findIndex(upOnboardItems, (item) => item.step === 'KYC');
              upOnboardItems[ind] = { ...upOnboardItems[ind], status: ONBOARD_ITEM_STATUS.ERROR };
              break;
            }

            case onboardFailuresConst.BANK_FAIURE: {
              const ind = _.findIndex(upOnboardItems, (item) => item.step === 'BANK_ACCOUNT_SETUP');
              const kycInd = _.findIndex(upOnboardItems, (item) => item.step === 'KYC');
              upOnboardItems[ind] = { ...upOnboardItems[ind], status: ONBOARD_ITEM_STATUS.ERROR };
              upOnboardItems[kycInd] = { ...upOnboardItems[kycInd], status: ONBOARD_ITEM_STATUS.ERROR };
              break;
            }
            default:
              break;
          }
        });
      }

      return updatedResponse;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getAccountAndPreferencesApi = createAsyncThunk(
  'karma/getAccountAndPreferences',
  async (params, thunkApi) => {
    try {
      const response = await axios.post(urls.accountAndPreference, params);
      if (response.data) {
        const timeStamp = new Date();
        return { ...response.data, updatedAt: timeStamp };
      }
      return thunkApi.rejectWithValue('Error! Try again later');
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const getLOCDashboardApi = createAsyncThunk('karma/GetLOCDashboardApi', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.GetLOCDashboardURL, params);
    if (response.data) {
      const timeStamp = new Date();
      return { ...response.data, updatedAt: timeStamp };
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});
// /api/credit/dashboard/GetLOCDashboard

export const getAllTransactionsApi = createAsyncThunk('karma/getAllTransactionsApi', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.listTransactions, params);
    if (response.data?.items) {
      return response.data.items;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getTransactionDetailsApi = createAsyncThunk('karma/getTransactionDetailsApi', async (params, thunkApi) => {
  try {
    // return await mockApiResponse(transactionDetailsRes.successProcessing, 100);
    const response = await axios.post(urls.transactionDetail, params);
    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getSettingsUserNbfcTermsApi = createAsyncThunk(
  'karma/getSettingsUserNbfcTerms',
  async (params, thunkApi) => {
    try {
      const response = await axios.post(urls.userNbfcTerms, params);
      if (response.data) {
        return response.data;
      }
      return thunkApi.rejectWithValue('Error! Try again later');
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const generateUserAccessTokenApi = createAsyncThunk(
  'karma/generateUserAccessToken',
  async (params, thunkApi) => {
    try {
      const response = await axios.post(urls.generateUserAccessToken, params);
      if (response.data) {
        return response.data;
      }
      return thunkApi.rejectWithValue('Error! Try again later');
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getTermsAndConditionApi = createAsyncThunk('karma/getTermsAndConditionApi', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.termsAndCondition, params);
    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const acceptTermsAndConditionApi = createAsyncThunk(
  'karma/acceptTermsAndCondition',
  async (params, thunkApi) => {
    try {
      const response = await axios.post(urls.acceptTermsAndCondition, await createTransferParams(params));
      if (response.data) {
        return response.data;
      }
      return thunkApi.rejectWithValue('Error! Try again later');
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getUsageAndRepaymentApi = createAsyncThunk('karma/getUsageAndRepayment', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.getUsageAndRepayment, params);
    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const checkUserEligibilityApi = createAsyncThunk('karma/checkUserEligibility', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.checkUserEligibility, params);
    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const consentSendOtpApi = createAsyncThunk('karma/consentSendOtpApi', async (params, thunkApi) => {
  try {
    const response = await axios.post(urls.consentSendOtp, params);
    if (response.data) {
      return response.data;
    }
    return thunkApi.rejectWithValue('Error! Try again later');
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getBankAccountPennyVerificationApi = createAsyncThunk(
  'karma/getBankAccountPennyVerificationApi',
  async (params, thunkApi) => {
    try {
      const response = await axios.post(urls.bankAccountPennyDropVerification, params);
      if (response.data) {
        return response.data;
      }
      return thunkApi.rejectWithValue('Error! Try again later');
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);