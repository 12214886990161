import { useEffect } from 'react';
import NProgress from 'nprogress';

import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
//
import CreditOfferNavbar from './CreditOfferNavbar';
// import DashboardSidebar from './DashboardSidebar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
}));
const APP_BAR_MOBILE = 64;
const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingLeft: 24,
  paddingRight: 24,
  paddingTop: APP_BAR_MOBILE
  // paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function CreditLayout() {
  const { isPlanDetailFetching } = useSelector(({ planReducer }) => planReducer);

  useEffect(() => {
    if (isPlanDetailFetching) {
      NProgress.start();
    } else {
      NProgress.done();
    }
  }, [isPlanDetailFetching]);

  return (
    <>
      <RootStyle>
        <CreditOfferNavbar />
        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
    </>
  );
}
